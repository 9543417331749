import { ChangeDetectorRef, Component } from '@angular/core';
import { BookingService } from '../../../../shared/services/booking.service';
import { finalize, takeUntil} from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.scss']
})
export class BookingDetailComponent {
  private unsubscribe: Subject<any> = new Subject();
  bookingId: any;
  constructor(
    private cdr: ChangeDetectorRef,
    private bookingService: BookingService,
    protected dialogRef: NbDialogRef<BookingDetailComponent>,
  ) {
    
  }

  ngOnInit() {
    this.getDetail();
  }

  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  bookingDetail:any;
  getDetail() {
    this.bookingService.getDetail(this.bookingId).pipe(
      takeUntil(this.unsubscribe),
      finalize(() => {
        this.cdr.markForCheck();
      }),
    ).subscribe((result: any) => {
        if (result.errorCode === '200') {
          this.bookingDetail = result.data;
          this.bookingDetail.chargeTime = formatDate(this.bookingDetail.startTime,'HH:mm','en-US') + ' - ' + formatDate(this.bookingDetail.endTime,'HH:mm','en-US');
          this.bookingDetail.duration = this.bookingDetail.duration / 60;
          this.bookingDetail.electricityPrice = new Intl.NumberFormat('en-US').format(this.bookingDetail.electricityPrice);
          this.bookingDetail.parkingPrice = new Intl.NumberFormat('en-US').format(this.bookingDetail.parkingPrice);
          this.bookingDetail.occupiedChargePrice = new Intl.NumberFormat('en-US').format(this.bookingDetail.occupiedChargePrice);
          this.bookingDetail.totalAmount = new Intl.NumberFormat('en-US').format(this.bookingDetail.totalAmount);
          this.bookingDetail.deposit = new Intl.NumberFormat('en-US').format(this.bookingDetail.deposit);
          this.bookingDetail.deviceImei = this.bookingDetail.deviceInfo?.imei;
          this.bookingDetail.maximumOutputPower = this.bookingDetail.connectorInfo?.maximumOutputPower;
        }
      });
  }
}
