
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TypesUtilsService } from '../../@core/models';
import { Response } from '../../@core/models/query-models/_new-response-body.model';

// const API_URL = "https://navitrack.fft.com.vn:8899/api/payment/getqr";
const API_URL = "http://navitrack.fft.com.vn:8989/api/payment/getqr";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient, private utils: TypesUtilsService) { }
  getQr(param?: any): Observable<Response> { 
    return this.http.post<Response>(API_URL, param, {headers: new HttpHeaders().set('Auth', 'False')});
  }
}

