<!-- 
<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"  id="kt_login" >
	<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile" >		
		<div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper" [ngStyle]="backgroundImageStyle">
			<div class="login-body">
				<div class="kt-login__body">
					<div class="kt-login__form">
						<div class="kt-login__title">
							<h3>{{ 'AUTH.FORGOT.TITLE' | translate }}</h3>
						</div>
				
						<kt-auth-notice></kt-auth-notice>
						<form class="" [formGroup]="forgotPasswordForm"  (ngSubmit)='submitForgotPassword(forgotPasswordForm)' action="#">

							<div class="form-group">
								<mat-form-field>
									<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
									<input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" autocomplete="off"/>
									<mat-error *ngIf="isControlHasError('email','required')">
										<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
									</mat-error>
									<mat-error *ngIf="isControlHasError('email','email')">
										<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
									</mat-error>
									<mat-error *ngIf="isControlHasError('email','minLength')">
										<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
									</mat-error>
									<mat-error *ngIf="isControlHasError('email','maxLength')">
										<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
									</mat-error>
								</mat-form-field>
							</div>
					
							<div class="kt-login__actions">
								<button type="button" routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-primary btn-elevate kt-login__btn-primary"
								style="margin-right: 15px;">
									{{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}
								</button>
								<button 
								 type="submit"
								 id="kt_login_signin_submit" 
								 [disabled]="forgotPasswordForm.invalid"
								 class="btn btn-primary btn-elevate kt-login__btn-primary"
								 [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

 -->

 <!--begin::Head-->

<div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
	<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
		<div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
			[ngStyle]="backgroundImageStyle">
			<div class=" select-default-language kt-align-right">

				<div ngbDropdown placement="bottom-right" class="kt-header__topbar-item kt-header__topbar-item--langs">
					<div ngbDropdownToggle class="kt-header__topbar-wrapper">
						<span class="kt-header__topbar-icon" style="cursor: pointer;">
							<img class="" src="{{language?.flag}}" alt="" /> <span>{{language.name}}</span>
						</span>
					</div>
					<div ngbDropdownMenu aria-labelledby="dropdownBasic1"
						class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
						<ul class="kt-nav kt-margin-t-10 kt-margin-b-10">
							<ng-container *ngFor="let language of languages">
								<li class="kt-nav__item" [ngClass]="{'kt-nav__item--active': language.active}">
									<a ngbDropdownToggle href="javascript:;" (click)="setLanguage(language.lang)"
										[ngClass]="{'kt-nav__link--active': language.active}" class="kt-nav__link">
										<span class="kt-nav__link-icon">
											<img src="{{language.flag}}">
										</span>
										<span class="kt-nav__link-text">{{language.name}}</span>
									</a>
								</li>
							</ng-container>
						</ul>
					</div>
				</div>
			</div>

			<div class="login-body">
				<div class="kt-login__body">
					<div class="kt-login__form">
						<div class="kt-login__logo" style="text-align: center;">
							<a href="javascript:;" class="">
								<img [src]="logo" alt="" style="max-width: 100px;max-height: 150px;">
							</a>
						</div>
						<div class="kt-login__title">
							<h3 class="login-title">{{ 'AUTH.FORGOT.TITLE' | translate }}</h3>
						</div>

						<kt-auth-notice></kt-auth-notice>
						<form [formGroup]="forgotPasswordForm" novalidate="novalidate">
							<div class="form-group validate is-invalid">
								<label class="label-login">{{ 'AUTH.INPUT.EMAIL' | translate }}</label>
								<input type="text" formControlName="email" name="email" autocomplete="off"
									class="form-control input-login" placeholder="{{ 'AUTH.INPUT.USERNAME' | translate }}"/>
								<div class="error invalid-feedback" *ngIf="isControlHasError('email','required')">
									<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate}}</strong>
								</div>
								<div class="error invalid-feedback" *ngIf="isControlHasError('email','minLength')">
									<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
								</div>
								<div class="error invalid-feedback" *ngIf="isControlHasError('email','maxLength')">
									<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
								</div>
							</div>
						</form>

						<div class="kt-login__actions">
							<button type="button" routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-primary btn-elevate kt-login__btn-primary"
							style="margin-right: 15px;">
								{{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}
							</button>
							<button 
							 type="submit" (click)="submitForgotPassword()"
							 id="kt_login_signin_submit" 
							 [disabled]="forgotPasswordForm.invalid"
							 class="btn btn-primary btn-elevate kt-login__btn-primary"
							 [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
