export class StatusType {
  text: string;
  icon: string;
  color: string;
  colorCode: string;
  colorCircleCode?: string;
  key: string;
  name: string;

  constructor(object: object) {
    this.text = object['text'] || "";
    this.icon = object['icon'] || "";
    this.color = object['color'] || "";
    this.colorCode = object['colorCode'] || "#ccc";
    this.colorCircleCode = object['colorCircleCode'] || "#ccc";
    this.key = object['key'] || "";
    this.name = object['name'] || "";

  }
}
