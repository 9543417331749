import { Component } from '@angular/core';
import { UserTreeService } from '../../../@core/services/user-tree.service';
import { NbSidebarService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive (stateChange)="stateChange($event)">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>
      <nb-sidebar class="user-sidebar" tag="user-sidebar" [state]="'collapsed'" (stateChange)="userStateChange($event)">
        <ng-content select="kt-user-tree-new"></ng-content>
      </nb-sidebar>
      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {

  constructor(private sideBar: NbSidebarService, private router: Router){

  }

  stateChange($event){
    if($event == 'expanded'){
      this.sideBar.collapse('user-sidebar');
    }
  }

  userStateChange($event){
    if($event == 'expanded'){
      this.sideBar.compact('menu-sidebar');
    }
  }
}
