import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PaymentComponent } from './payment.component';

const routes: Routes = [
  { path: 'info',
    component: PaymentComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [],
  exports: [RouterModule],
})
export class PaymentRoutingModule {
}
