import {Injectable, EventEmitter} from '@angular/core';
// RxJS
import {Observable} from 'rxjs';
// NGRX
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {tap, finalize} from 'rxjs/operators';
import {AppState} from "../../@core/reducers";
import {AuthService, User} from "../../@core/auth";
import {currentUser} from "../../@core/auth";

const DATE_FORMAT: DateFormat[] = [
  {
    type: 'd.m.Y',
    format: 'DD.MM.YYYY',
    pipe: 'dd.MM.yyyy',
    name: '24.12.2016 (d.m.Y)',
    amchart: 'dd.MM.yyyy',
  },
  {
    type: 'Y.m.d',
    format: 'YYYY.MM.DD',
    pipe: 'yyyy.MM.dd',
    name: '2016.12.24 (Y.m.d)',
    amchart: 'YYYY.MM.dd',
  },
  {
    type: 'm.d.Y',
    format: 'MM.DD.YYYY',
    pipe: 'MM.dd.yyyy',
    name: '12.12.2016 (m.d.Y)',
    amchart: 'MM.dd.YYYY',
  },
  {
    type: 'd/m/Y',
    format: 'DD/MM/YYYY',
    pipe: 'dd/MM/yyyy',
    name: '24/12/2016 (d/m/Y)',
    amchart: 'dd/MM/YYYY',
  },
  {
    type: 'm/d/Y',
    pipe: 'MM.dd.yyyy',
    format: 'MM/DD/YYYY',
    name: '12/12/2016 (m/d/Y)',
    amchart: 'MM/dd/YYYY',
  },
  {
    type: 'Y/m/d',
    format: 'YYYY/MM/DD',
    pipe: 'yyyy/MM/dd',
    name: '2016/12/12 (Y/m/d)',
    amchart: 'YYYY/MM/dd',
  },
];
const TIME_FORMAT: TimeFormat[] = [
  {
    type: 'H:m',
    format: 'HH:mm',
    name: '20:45',
    amchart: 'HH:mm',
  },
  {
    type: 'h:m a',
    format: 'h:m a',
    name: '9:3 am',
    amchart: 'h:m a',
  },
  {
    type: 'hh:mm A',
    format: 'hh:mm A',
    name: '09:03 AM',
    amchart: 'hh:mm A',
  },
  {
    type: 'hh:mm:ss',
    format: 'hh:mm:ss',
    name: '09:03:01',
    amchart: 'hh:mm:ss',
  },
  {
    type: 'H:m:s',
    format: 'HH:mm:ss',
    name: '20:03:01',
    amchart: 'HH:mm:ss',
  },
  {
    type: 'H:m:s A',
    format: 'HH:mm:ss A',
    name: '20:03:01 PM',
    amchart: 'HH:mm:ss A',
  },
];

const WEEKFIRSTDAY = [
  {id: '1', name: 'Monday'},
  {id: '2', name: 'Tuesday'},
  {id: '3', name: 'Wednesday'},
  {id: '4', name: 'Thursday'},
  {id: '5', name: 'Friday'},
  {id: '6', name: 'Saturday'},
  {id: '0', name: 'Sunday'},
];

const ROLES = [
  {id: '2', name: 'Distributor'},
  {id: '3', name: 'User'},
];

const USERTYPE = [
  {
    id: 1,
    name: 'Nhà phân phối',
    key: 'MANAGE.USER.PARAMETER.DISTRIBUTOR',
    icon: 'icon-user-alt user-distributor',
    content: "<i class='fa fa-cutlery'></i> Cutlery",
  },
  {
    id: 2,
    name: 'Đối tác',
    key: 'MANAGE.USER.PARAMETER.PARTNER',
    icon: 'icon-user-alt user-end-user',
    content: "<i class='fa fa-cutlery'></i> Cutlery",
  },
  {
    id: 3,
    name: 'Người dùng',
    key: 'MANAGE.USER.PARAMETER.USER',
    icon: 'icon-user-alt user-end-user',
    content: "<i class='fa fa-cutlery'></i> Cutlery",
  },
];

const TEMPERATURE = [
  {
    id: 'celsius',
    name: 'celsius',
  },
  {
    id: 'fahrenheit',
    name: 'fahrenheit',
  },
];

const DISTANCE = [
  {
    id: 'kilometer',
    name: 'kilometer',
  },
  {
    id: 'mile',
    name: 'mile',
  },
];

const DECIMAL = [
  {id: ',', name: 'comma'},
  {id: '.', name: 'dot'},
];

const VOLUME = [
  {
    id: 'litre',
    name: 'litre',
  },
  {
    id: 'gallon_uk',
    name: 'gallon (UK)',
  },
  {
    id: 'gallon_us',
    name: 'gallon (US)',
  },
];

const WEIGHT = [
  {
    id: 'kilogram',
    name: 'kilogram',
  },
  {
    id: 'pound',
    name: 'pound',
  },
];

const LANGUAGE: Language[] = [
  {
    key: 'vi',
    name: 'Việt Nam',
  },
  {
    key: 'en',
    name: 'English',
  },
];
const ELECTRIC_TYPE: any[] = [
  {
    key: 'AC',
    name: 'AC',
  },
  {
    key: 'DC',
    name: 'DC',
  },
];
const CHARGE_TYPE: any[] = [
  {
    key: 'MOTORBIKE',
    name: 'Xe máy điện',
  },
  {
    key: 'CAR',
    name: 'Ô tô điện',
  },
  {
    key: 'OTHERS',
    name: 'Các phương tiện khác',
  },
  {
    key: 'ALL',
    name: 'Tất cả',
  },
];
const TIME_ZONES: TimeZone[] = [
  {
    countryCode: 'AF',
    countryName: 'Afghanistan',
    timeZone: 'Asia/Kabul',
    GMTOffset: 'UTC +04:30',
    GMTOffsetMinute: '+270',
  },
  {
    countryCode: 'AX',
    countryName: 'Aland Islands',
    timeZone: 'Europe/Mariehamn',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'AL',
    countryName: 'Albania',
    timeZone: 'Europe/Tirane',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'DZ',
    countryName: 'Algeria',
    timeZone: 'Africa/Algiers',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'AS',
    countryName: 'American Samoa',
    timeZone: 'Pacific/Pago_Pago',
    GMTOffset: 'UTC -11:00',
    GMTOffsetMinute: '-660',
  },
  {
    countryCode: 'AD',
    countryName: 'Andorra',
    timeZone: 'Europe/Andorra',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'AO',
    countryName: 'Angola',
    timeZone: 'Africa/Luanda',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'AI',
    countryName: 'Anguilla',
    timeZone: 'America/Anguilla',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    timeZone: 'Antarctica/Casey',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    timeZone: 'Antarctica/Davis',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    timeZone: 'Antarctica/DumontDUrville',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    timeZone: 'Antarctica/Mawson',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    timeZone: 'Antarctica/McMurdo',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    timeZone: 'Antarctica/Palmer',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    timeZone: 'Antarctica/Rothera',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    timeZone: 'Antarctica/Syowa',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    timeZone: 'Antarctica/Troll',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    timeZone: 'Antarctica/Vostok',
    GMTOffset: 'UTC +06:00',
    GMTOffsetMinute: '+360',
  },
  {
    countryCode: 'AG',
    countryName: 'Antigua and Barbuda',
    timeZone: 'America/Antigua',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/Buenos_Aires',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/Catamarca',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/Cordoba',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/Jujuy',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/La_Rioja',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/Mendoza',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/Rio_Gallegos',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/Salta',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/San_Juan',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/San_Luis',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/Tucuman',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    timeZone: 'America/Argentina/Ushuaia',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'AM',
    countryName: 'Armenia',
    timeZone: 'Asia/Yerevan',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'AW',
    countryName: 'Aruba',
    timeZone: 'America/Aruba',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Antarctica/Macquarie',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Adelaide',
    GMTOffset: 'UTC +09:30',
    GMTOffsetMinute: '+570',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Brisbane',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Broken_Hill',
    GMTOffset: 'UTC +09:30',
    GMTOffsetMinute: '+570',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Currie',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Darwin',
    GMTOffset: 'UTC +09:30',
    GMTOffsetMinute: '+570',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Eucla',
    GMTOffset: 'UTC +08:45',
    GMTOffsetMinute: '+525',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Hobart',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Lindeman',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Lord_Howe',
    GMTOffset: 'UTC +10:30',
    GMTOffsetMinute: '+630',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Melbourne',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Perth',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    timeZone: 'Australia/Sydney',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    timeZone: 'Europe/Vienna',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    timeZone: 'Asia/Baku',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'BS',
    countryName: 'Bahamas',
    timeZone: 'America/Nassau',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'BH',
    countryName: 'Bahrain',
    timeZone: 'Asia/Bahrain',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    timeZone: 'Asia/Dhaka',
    GMTOffset: 'UTC +06:00',
    GMTOffsetMinute: '+360',
  },
  {
    countryCode: 'BB',
    countryName: 'Barbados',
    timeZone: 'America/Barbados',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'BY',
    countryName: 'Belarus',
    timeZone: 'Europe/Minsk',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    timeZone: 'Europe/Brussels',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'BZ',
    countryName: 'Belize',
    timeZone: 'America/Belize',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'BJ',
    countryName: 'Benin',
    timeZone: 'Africa/Porto-Novo',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'BM',
    countryName: 'Bermuda',
    timeZone: 'Atlantic/Bermuda',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'BT',
    countryName: 'Bhutan',
    timeZone: 'Asia/Thimphu',
    GMTOffset: 'UTC +06:00',
    GMTOffsetMinute: '+360',
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivia',
    timeZone: 'America/La_Paz',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'BQ',
    countryName: 'Bonaire, Saint Eustatius and Saba',
    timeZone: 'America/Kralendijk',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    timeZone: 'Europe/Sarajevo',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'BW',
    countryName: 'Botswana',
    timeZone: 'Africa/Gaborone',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Araguaina',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Bahia',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Belem',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Boa_Vista',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Campo_Grande',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Cuiaba',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Eirunepe',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Fortaleza',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Maceio',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Manaus',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Noronha',
    GMTOffset: 'UTC -02:00',
    GMTOffsetMinute: '-120',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Porto_Velho',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Recife',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Rio_Branco',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Santarem',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    timeZone: 'America/Sao_Paulo',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    timeZone: 'Indian/Chagos',
    GMTOffset: 'UTC +06:00',
    GMTOffsetMinute: '+360',
  },
  {
    countryCode: 'VG',
    countryName: 'British Virgin Islands',
    timeZone: 'America/Tortola',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'BN',
    countryName: 'Brunei',
    timeZone: 'Asia/Brunei',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    timeZone: 'Europe/Sofia',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'BF',
    countryName: 'Burkina Faso',
    timeZone: 'Africa/Ouagadougou',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'BI',
    countryName: 'Burundi',
    timeZone: 'Africa/Bujumbura',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'KH',
    countryName: 'Cambodia',
    timeZone: 'Asia/Phnom_Penh',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'CM',
    countryName: 'Cameroon',
    timeZone: 'Africa/Douala',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Atikokan',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Blanc-Sablon',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Cambridge_Bay',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Creston',
    GMTOffset: 'UTC -07:00',
    GMTOffsetMinute: '-420',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Dawson',
    GMTOffset: 'UTC -07:00',
    GMTOffsetMinute: '-420',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Dawson_Creek',
    GMTOffset: 'UTC -07:00',
    GMTOffsetMinute: '-420',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Edmonton',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Fort_Nelson',
    GMTOffset: 'UTC -07:00',
    GMTOffsetMinute: '-420',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Glace_Bay',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Goose_Bay',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Halifax',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Inuvik',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Iqaluit',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Moncton',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Nipigon',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Pangnirtung',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Rainy_River',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Rankin_Inlet',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Regina',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Resolute',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/St_Johns',
    GMTOffset: 'UTC -02:30',
    GMTOffsetMinute: '-150',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Swift_Current',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Thunder_Bay',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Toronto',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Vancouver',
    GMTOffset: 'UTC -07:00',
    GMTOffsetMinute: '-420',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Whitehorse',
    GMTOffset: 'UTC -07:00',
    GMTOffsetMinute: '-420',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Winnipeg',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Yellowknife',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'CV',
    countryName: 'Cape Verde',
    timeZone: 'Atlantic/Cape_Verde',
    GMTOffset: 'UTC -01:00',
    GMTOffsetMinute: '-60',
  },
  {
    countryCode: 'KY',
    countryName: 'Cayman Islands',
    timeZone: 'America/Cayman',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'CF',
    countryName: 'Central African Republic',
    timeZone: 'Africa/Bangui',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'TD',
    countryName: 'Chad',
    timeZone: 'Africa/Ndjamena',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    timeZone: 'America/Punta_Arenas',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    timeZone: 'America/Santiago',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    timeZone: 'Pacific/Easter',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    timeZone: 'Asia/Shanghai',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    timeZone: 'Asia/Urumqi',
    GMTOffset: 'UTC +06:00',
    GMTOffsetMinute: '+360',
  },
  {
    countryCode: 'CX',
    countryName: 'Christmas Island',
    timeZone: 'Indian/Christmas',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'CC',
    countryName: 'Cocos Islands',
    timeZone: 'Indian/Cocos',
    GMTOffset: 'UTC +06:30',
    GMTOffsetMinute: '+390',
  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
    timeZone: 'America/Bogota',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'KM',
    countryName: 'Comoros',
    timeZone: 'Indian/Comoro',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'CK',
    countryName: 'Cook Islands',
    timeZone: 'Pacific/Rarotonga',
    GMTOffset: 'UTC -10:00',
    GMTOffsetMinute: '-600',
  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    timeZone: 'America/Costa_Rica',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    timeZone: 'Europe/Zagreb',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'CU',
    countryName: 'Cuba',
    timeZone: 'America/Havana',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'CW',
    countryName: 'Curaçao',
    timeZone: 'America/Curacao',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    timeZone: 'Asia/Famagusta',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    timeZone: 'Asia/Nicosia',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'CZ',
    countryName: 'Czech Republic',
    timeZone: 'Europe/Prague',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'CD',
    countryName: 'Democratic Republic of the Congo',
    timeZone: 'Africa/Kinshasa',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'CD',
    countryName: 'Democratic Republic of the Congo',
    timeZone: 'Africa/Lubumbashi',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    timeZone: 'Europe/Copenhagen',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'DJ',
    countryName: 'Djibouti',
    timeZone: 'Africa/Djibouti',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'DM',
    countryName: 'Dominica',
    timeZone: 'America/Dominica',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    timeZone: 'America/Santo_Domingo',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'TL',
    countryName: 'East Timor',
    timeZone: 'Asia/Dili',
    GMTOffset: 'UTC +09:00',
    GMTOffsetMinute: '+540',
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    timeZone: 'America/Guayaquil',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    timeZone: 'Pacific/Galapagos',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    timeZone: 'Africa/Cairo',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador',
    timeZone: 'America/El_Salvador',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'GQ',
    countryName: 'Equatorial Guinea',
    timeZone: 'Africa/Malabo',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'ER',
    countryName: 'Eritrea',
    timeZone: 'Africa/Asmara',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    timeZone: 'Europe/Tallinn',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'ET',
    countryName: 'Ethiopia',
    timeZone: 'Africa/Addis_Ababa',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'FK',
    countryName: 'Falkland Islands',
    timeZone: 'Atlantic/Stanley',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    timeZone: 'Atlantic/Faroe',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'FJ',
    countryName: 'Fiji',
    timeZone: 'Pacific/Fiji',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    timeZone: 'Europe/Helsinki',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    timeZone: 'Europe/Paris',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'GF',
    countryName: 'French Guiana',
    timeZone: 'America/Cayenne',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    timeZone: 'Pacific/Gambier',
    GMTOffset: 'UTC -09:00',
    GMTOffsetMinute: '-540',
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    timeZone: 'Pacific/Marquesas',
    GMTOffset: 'UTC -09:30',
    GMTOffsetMinute: '-570',
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    timeZone: 'Pacific/Tahiti',
    GMTOffset: 'UTC -10:00',
    GMTOffsetMinute: '-600',
  },
  {
    countryCode: 'TF',
    countryName: 'French Southern Territories',
    timeZone: 'Indian/Kerguelen',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'GA',
    countryName: 'Gabon',
    timeZone: 'Africa/Libreville',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'GM',
    countryName: 'Gambia',
    timeZone: 'Africa/Banjul',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
    timeZone: 'Asia/Tbilisi',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    timeZone: 'Europe/Berlin',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    timeZone: 'Europe/Busingen',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'GH',
    countryName: 'Ghana',
    timeZone: 'Africa/Accra',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    timeZone: 'Europe/Gibraltar',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    timeZone: 'Europe/Athens',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    timeZone: 'America/Danmarkshavn',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    timeZone: 'America/Godthab',
    GMTOffset: 'UTC -02:00',
    GMTOffsetMinute: '-120',
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    timeZone: 'America/Scoresbysund',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    timeZone: 'America/Thule',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'GD',
    countryName: 'Grenada',
    timeZone: 'America/Grenada',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'GP',
    countryName: 'Guadeloupe',
    timeZone: 'America/Guadeloupe',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'GU',
    countryName: 'Guam',
    timeZone: 'Pacific/Guam',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala',
    timeZone: 'America/Guatemala',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'GG',
    countryName: 'Guernsey',
    timeZone: 'Europe/Guernsey',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'GN',
    countryName: 'Guinea',
    timeZone: 'Africa/Conakry',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
    timeZone: 'Africa/Bissau',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'GY',
    countryName: 'Guyana',
    timeZone: 'America/Guyana',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'HT',
    countryName: 'Haiti',
    timeZone: 'America/Port-au-Prince',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'HN',
    countryName: 'Honduras',
    timeZone: 'America/Tegucigalpa',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    timeZone: 'Asia/Hong_Kong',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    timeZone: 'Europe/Budapest',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    timeZone: 'Atlantic/Reykjavik',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    timeZone: 'Asia/Kolkata',
    GMTOffset: 'UTC +05:30',
    GMTOffsetMinute: '+330',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    timeZone: 'Asia/Jakarta',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    timeZone: 'Asia/Jayapura',
    GMTOffset: 'UTC +09:00',
    GMTOffsetMinute: '+540',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    timeZone: 'Asia/Makassar',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    timeZone: 'Asia/Pontianak',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'IR',
    countryName: 'Iran',
    timeZone: 'Asia/Tehran',
    GMTOffset: 'UTC +04:30',
    GMTOffsetMinute: '+270',
  },
  {
    countryCode: 'IQ',
    countryName: 'Iraq',
    timeZone: 'Asia/Baghdad',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    timeZone: 'Europe/Dublin',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'IM',
    countryName: 'Isle of Man',
    timeZone: 'Europe/Isle_of_Man',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    timeZone: 'Asia/Jerusalem',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    timeZone: 'Europe/Rome',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'CI',
    countryName: 'Ivory Coast',
    timeZone: 'Africa/Abidjan',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'JM',
    countryName: 'Jamaica',
    timeZone: 'America/Jamaica',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    timeZone: 'Asia/Tokyo',
    GMTOffset: 'UTC +09:00',
    GMTOffsetMinute: '+540',
  },
  {
    countryCode: 'JE',
    countryName: 'Jersey',
    timeZone: 'Europe/Jersey',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'JO',
    countryName: 'Jordan',
    timeZone: 'Asia/Amman',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    timeZone: 'Asia/Almaty',
    GMTOffset: 'UTC +06:00',
    GMTOffsetMinute: '+360',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    timeZone: 'Asia/Aqtau',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    timeZone: 'Asia/Aqtobe',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    timeZone: 'Asia/Atyrau',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    timeZone: 'Asia/Oral',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    timeZone: 'Asia/Qostanay',
    GMTOffset: 'UTC +06:00',
    GMTOffsetMinute: '+360',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    timeZone: 'Asia/Qyzylorda',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'KE',
    countryName: 'Kenya',
    timeZone: 'Africa/Nairobi',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    timeZone: 'Pacific/Enderbury',
    GMTOffset: 'UTC +13:00',
    GMTOffsetMinute: '+780',
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    timeZone: 'Pacific/Kiritimati',
    GMTOffset: 'UTC +14:00',
    GMTOffsetMinute: '+840',
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    timeZone: 'Pacific/Tarawa',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    timeZone: 'Asia/Kuwait',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    timeZone: 'Asia/Bishkek',
    GMTOffset: 'UTC +06:00',
    GMTOffsetMinute: '+360',
  },
  {
    countryCode: 'LA',
    countryName: 'Laos',
    timeZone: 'Asia/Vientiane',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    timeZone: 'Europe/Riga',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'LB',
    countryName: 'Lebanon',
    timeZone: 'Asia/Beirut',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'LS',
    countryName: 'Lesotho',
    timeZone: 'Africa/Maseru',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'LR',
    countryName: 'Liberia',
    timeZone: 'Africa/Monrovia',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'LY',
    countryName: 'Libya',
    timeZone: 'Africa/Tripoli',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    timeZone: 'Europe/Vaduz',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    timeZone: 'Europe/Vilnius',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    timeZone: 'Europe/Luxembourg',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'MO',
    countryName: 'Macao',
    timeZone: 'Asia/Macau',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'MK',
    countryName: 'Macedonia',
    timeZone: 'Europe/Skopje',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'MG',
    countryName: 'Madagascar',
    timeZone: 'Indian/Antananarivo',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'MW',
    countryName: 'Malawi',
    timeZone: 'Africa/Blantyre',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    timeZone: 'Asia/Kuala_Lumpur',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    timeZone: 'Asia/Kuching',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'MV',
    countryName: 'Maldives',
    timeZone: 'Indian/Maldives',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'ML',
    countryName: 'Mali',
    timeZone: 'Africa/Bamako',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    timeZone: 'Europe/Malta',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    timeZone: 'Pacific/Kwajalein',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    timeZone: 'Pacific/Majuro',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'MQ',
    countryName: 'Martinique',
    timeZone: 'America/Martinique',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'MR',
    countryName: 'Mauritania',
    timeZone: 'Africa/Nouakchott',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'MU',
    countryName: 'Mauritius',
    timeZone: 'Indian/Mauritius',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'YT',
    countryName: 'Mayotte',
    timeZone: 'Indian/Mayotte',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Bahia_Banderas',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Cancun',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Chihuahua',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Hermosillo',
    GMTOffset: 'UTC -07:00',
    GMTOffsetMinute: '-420',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Matamoros',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Mazatlan',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Merida',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Mexico_City',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Monterrey',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Ojinaga',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    timeZone: 'America/Tijuana',
    GMTOffset: 'UTC -07:00',
    GMTOffsetMinute: '-420',
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia',
    timeZone: 'Pacific/Chuuk',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia',
    timeZone: 'Pacific/Kosrae',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia',
    timeZone: 'Pacific/Pohnpei',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova',
    timeZone: 'Europe/Chisinau',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'MC',
    countryName: 'Monaco',
    timeZone: 'Europe/Monaco',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    timeZone: 'Asia/Choibalsan',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    timeZone: 'Asia/Hovd',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    timeZone: 'Asia/Ulaanbaatar',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'ME',
    countryName: 'Montenegro',
    timeZone: 'Europe/Podgorica',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'MS',
    countryName: 'Montserrat',
    timeZone: 'America/Montserrat',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'MA',
    countryName: 'Morocco',
    timeZone: 'Africa/Casablanca',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    timeZone: 'Africa/Maputo',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'MM',
    countryName: 'Myanmar',
    timeZone: 'Asia/Yangon',
    GMTOffset: 'UTC +06:30',
    GMTOffsetMinute: '+390',
  },
  {
    countryCode: 'NA',
    countryName: 'Namibia',
    timeZone: 'Africa/Windhoek',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'NR',
    countryName: 'Nauru',
    timeZone: 'Pacific/Nauru',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal',
    timeZone: 'Asia/Kathmandu',
    GMTOffset: 'UTC +05:45',
    GMTOffsetMinute: '+345',
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    timeZone: 'Europe/Amsterdam',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'NC',
    countryName: 'New Caledonia',
    timeZone: 'Pacific/Noumea',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    timeZone: 'Pacific/Auckland',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    timeZone: 'Pacific/Chatham',
    GMTOffset: 'UTC +12:45',
    GMTOffsetMinute: '+765',
  },
  {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    timeZone: 'America/Managua',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'NE',
    countryName: 'Niger',
    timeZone: 'Africa/Niamey',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'NG',
    countryName: 'Nigeria',
    timeZone: 'Africa/Lagos',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'NU',
    countryName: 'Niue',
    timeZone: 'Pacific/Niue',
    GMTOffset: 'UTC -11:00',
    GMTOffsetMinute: '-660',
  },
  {
    countryCode: 'NF',
    countryName: 'Norfolk Island',
    timeZone: 'Pacific/Norfolk',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'KP',
    countryName: 'North Korea',
    timeZone: 'Asia/Pyongyang',
    GMTOffset: 'UTC +09:00',
    GMTOffsetMinute: '+540',
  },
  {
    countryCode: 'MP',
    countryName: 'Northern Mariana Islands',
    timeZone: 'Pacific/Saipan',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    timeZone: 'Europe/Oslo',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'OM',
    countryName: 'Oman',
    timeZone: 'Asia/Muscat',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan',
    timeZone: 'Asia/Karachi',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'PW',
    countryName: 'Palau',
    timeZone: 'Pacific/Palau',
    GMTOffset: 'UTC +09:00',
    GMTOffsetMinute: '+540',
  },
  {
    countryCode: 'PS',
    countryName: 'Palestinian Territory',
    timeZone: 'Asia/Gaza',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'PS',
    countryName: 'Palestinian Territory',
    timeZone: 'Asia/Hebron',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'PA',
    countryName: 'Panama',
    timeZone: 'America/Panama',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    timeZone: 'Pacific/Bougainville',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    timeZone: 'Pacific/Port_Moresby',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay',
    timeZone: 'America/Asuncion',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
    timeZone: 'America/Lima',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    timeZone: 'Asia/Manila',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'PN',
    countryName: 'Pitcairn',
    timeZone: 'Pacific/Pitcairn',
    GMTOffset: 'UTC -08:00',
    GMTOffsetMinute: '-480',
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    timeZone: 'Europe/Warsaw',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    timeZone: 'Atlantic/Azores',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    timeZone: 'Atlantic/Madeira',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    timeZone: 'Europe/Lisbon',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    timeZone: 'America/Puerto_Rico',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
    timeZone: 'Asia/Qatar',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'CG',
    countryName: 'Republic of the Congo',
    timeZone: 'Africa/Brazzaville',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'RE',
    countryName: 'Reunion',
    timeZone: 'Indian/Reunion',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    timeZone: 'Europe/Bucharest',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Anadyr',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Barnaul',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Chita',
    GMTOffset: 'UTC +09:00',
    GMTOffsetMinute: '+540',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Irkutsk',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Kamchatka',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Khandyga',
    GMTOffset: 'UTC +09:00',
    GMTOffsetMinute: '+540',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Krasnoyarsk',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Magadan',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Novokuznetsk',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Novosibirsk',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Omsk',
    GMTOffset: 'UTC +06:00',
    GMTOffsetMinute: '+360',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Sakhalin',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Srednekolymsk',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Tomsk',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Ust-Nera',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Vladivostok',
    GMTOffset: 'UTC +10:00',
    GMTOffsetMinute: '+600',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Yakutsk',
    GMTOffset: 'UTC +09:00',
    GMTOffsetMinute: '+540',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Asia/Yekaterinburg',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Europe/Astrakhan',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Europe/Kaliningrad',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Europe/Kirov',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Europe/Moscow',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Europe/Samara',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Europe/Saratov',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Europe/Ulyanovsk',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    timeZone: 'Europe/Volgograd',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'RW',
    countryName: 'Rwanda',
    timeZone: 'Africa/Kigali',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'BL',
    countryName: 'Saint Barthélemy',
    timeZone: 'America/St_Barthelemy',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'SH',
    countryName: 'Saint Helena',
    timeZone: 'Atlantic/St_Helena',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    timeZone: 'America/St_Kitts',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'LC',
    countryName: 'Saint Lucia',
    timeZone: 'America/St_Lucia',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'MF',
    countryName: 'Saint Martin',
    timeZone: 'America/Marigot',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
    timeZone: 'America/Miquelon',
    GMTOffset: 'UTC -02:00',
    GMTOffsetMinute: '-120',
  },
  {
    countryCode: 'VC',
    countryName: 'Saint Vincent and the Grenadines',
    timeZone: 'America/St_Vincent',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'WS',
    countryName: 'Samoa',
    timeZone: 'Pacific/Apia',
    GMTOffset: 'UTC +13:00',
    GMTOffsetMinute: '+780',
  },
  {
    countryCode: 'SM',
    countryName: 'San Marino',
    timeZone: 'Europe/San_Marino',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'ST',
    countryName: 'Sao Tome and Principe',
    timeZone: 'Africa/Sao_Tome',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    timeZone: 'Asia/Riyadh',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'SN',
    countryName: 'Senegal',
    timeZone: 'Africa/Dakar',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
    timeZone: 'Europe/Belgrade',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'SC',
    countryName: 'Seychelles',
    timeZone: 'Indian/Mahe',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'SL',
    countryName: 'Sierra Leone',
    timeZone: 'Africa/Freetown',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    timeZone: 'Asia/Singapore',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'SX',
    countryName: 'Sint Maarten',
    timeZone: 'America/Lower_Princes',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    timeZone: 'Europe/Bratislava',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    timeZone: 'Europe/Ljubljana',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    timeZone: 'Pacific/Guadalcanal',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'SO',
    countryName: 'Somalia',
    timeZone: 'Africa/Mogadishu',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    timeZone: 'Africa/Johannesburg',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'GS',
    countryName: 'South Georgia and the South Sandwich Islands',
    timeZone: 'Atlantic/South_Georgia',
    GMTOffset: 'UTC -02:00',
    GMTOffsetMinute: '-120',
  },
  {
    countryCode: 'KR',
    countryName: 'South Korea',
    timeZone: 'Asia/Seoul',
    GMTOffset: 'UTC +09:00',
    GMTOffsetMinute: '+540',
  },
  {
    countryCode: 'SS',
    countryName: 'South Sudan',
    timeZone: 'Africa/Juba',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    timeZone: 'Africa/Ceuta',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    timeZone: 'Atlantic/Canary',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    timeZone: 'Europe/Madrid',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    timeZone: 'Asia/Colombo',
    GMTOffset: 'UTC +05:30',
    GMTOffsetMinute: '+330',
  },
  {
    countryCode: 'SD',
    countryName: 'Sudan',
    timeZone: 'Africa/Khartoum',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'SR',
    countryName: 'Suriname',
    timeZone: 'America/Paramaribo',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'SJ',
    countryName: 'Svalbard and Jan Mayen',
    timeZone: 'Arctic/Longyearbyen',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'SZ',
    countryName: 'Swaziland',
    timeZone: 'Africa/Mbabane',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    timeZone: 'Europe/Stockholm',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    timeZone: 'Europe/Zurich',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'SY',
    countryName: 'Syria',
    timeZone: 'Asia/Damascus',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan',
    timeZone: 'Asia/Taipei',
    GMTOffset: 'UTC +08:00',
    GMTOffsetMinute: '+480',
  },
  {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    timeZone: 'Asia/Dushanbe',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'TZ',
    countryName: 'Tanzania',
    timeZone: 'Africa/Dar_es_Salaam',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    timeZone: 'Asia/Bangkok',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'TG',
    countryName: 'Togo',
    timeZone: 'Africa/Lome',
    GMTOffset: 'UTC',
    GMTOffsetMinute: '0',
  },
  {
    countryCode: 'TK',
    countryName: 'Tokelau',
    timeZone: 'Pacific/Fakaofo',
    GMTOffset: 'UTC +13:00',
    GMTOffsetMinute: '+780',
  },
  {
    countryCode: 'TO',
    countryName: 'Tonga',
    timeZone: 'Pacific/Tongatapu',
    GMTOffset: 'UTC +13:00',
    GMTOffsetMinute: '+780',
  },
  {
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    timeZone: 'America/Port_of_Spain',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'TN',
    countryName: 'Tunisia',
    timeZone: 'Africa/Tunis',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    timeZone: 'Europe/Istanbul',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    timeZone: 'Asia/Ashgabat',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
    timeZone: 'America/Grand_Turk',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'TV',
    countryName: 'Tuvalu',
    timeZone: 'Pacific/Funafuti',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'VI',
    countryName: 'U.S. Virgin Islands',
    timeZone: 'America/St_Thomas',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'UG',
    countryName: 'Uganda',
    timeZone: 'Africa/Kampala',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    timeZone: 'Europe/Kiev',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    timeZone: 'Europe/Simferopol',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    timeZone: 'Europe/Uzhgorod',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    timeZone: 'Europe/Zaporozhye',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    timeZone: 'Asia/Dubai',
    GMTOffset: 'UTC +04:00',
    GMTOffsetMinute: '+240',
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    timeZone: 'Europe/London',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Adak',
    GMTOffset: 'UTC -09:00',
    GMTOffsetMinute: '-540',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Anchorage',
    GMTOffset: 'UTC -08:00',
    GMTOffsetMinute: '-480',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Boise',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Chicago',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Denver',
    GMTOffset: 'UTC -06:00',
    GMTOffsetMinute: '-360',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Detroit',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Indiana/Indianapolis',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Indiana/Knox',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Indiana/Marengo',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Indiana/Petersburg',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Indiana/Tell_City',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Indiana/Vevay',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Indiana/Vincennes',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Indiana/Winamac',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Juneau',
    GMTOffset: 'UTC -08:00',
    GMTOffsetMinute: '-480',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Kentucky/Louisville',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Kentucky/Monticello',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Los_Angeles',
    GMTOffset: 'UTC -07:00',
    GMTOffsetMinute: '-420',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Menominee',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Metlakatla',
    GMTOffset: 'UTC -08:00',
    GMTOffsetMinute: '-480',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/New_York',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Nome',
    GMTOffset: 'UTC -08:00',
    GMTOffsetMinute: '-480',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/North_Dakota/Beulah',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/North_Dakota/Center',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/North_Dakota/New_Salem',
    GMTOffset: 'UTC -05:00',
    GMTOffsetMinute: '-300',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Phoenix',
    GMTOffset: 'UTC -07:00',
    GMTOffsetMinute: '-420',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Sitka',
    GMTOffset: 'UTC -08:00',
    GMTOffsetMinute: '-480',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'America/Yakutat',
    GMTOffset: 'UTC -08:00',
    GMTOffsetMinute: '-480',
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    timeZone: 'Pacific/Honolulu',
    GMTOffset: 'UTC -10:00',
    GMTOffsetMinute: '-600',
  },
  {
    countryCode: 'UM',
    countryName: 'United States Minor Outlying Islands',
    timeZone: 'Pacific/Midway',
    GMTOffset: 'UTC -11:00',
    GMTOffsetMinute: '-660',
  },
  {
    countryCode: 'UM',
    countryName: 'United States Minor Outlying Islands',
    timeZone: 'Pacific/Wake',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'UY',
    countryName: 'Uruguay',
    timeZone: 'America/Montevideo',
    GMTOffset: 'UTC -03:00',
    GMTOffsetMinute: '-180',
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    timeZone: 'Asia/Samarkand',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    timeZone: 'Asia/Tashkent',
    GMTOffset: 'UTC +05:00',
    GMTOffsetMinute: '+300',
  },
  {
    countryCode: 'VU',
    countryName: 'Vanuatu',
    timeZone: 'Pacific/Efate',
    GMTOffset: 'UTC +11:00',
    GMTOffsetMinute: '+660',
  },
  {
    countryCode: 'VA',
    countryName: 'Vatican',
    timeZone: 'Europe/Vatican',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'VE',
    countryName: 'Venezuela',
    timeZone: 'America/Caracas',
    GMTOffset: 'UTC -04:00',
    GMTOffsetMinute: '-240',
  },
  {
    countryCode: 'VN',
    countryName: 'Vietnam',
    timeZone: 'Asia/Ho_Chi_Minh',
    GMTOffset: 'UTC +07:00',
    GMTOffsetMinute: '+420',
  },
  {
    countryCode: 'WF',
    countryName: 'Wallis and Futuna',
    timeZone: 'Pacific/Wallis',
    GMTOffset: 'UTC +12:00',
    GMTOffsetMinute: '+720',
  },
  {
    countryCode: 'EH',
    countryName: 'Western Sahara',
    timeZone: 'Africa/El_Aaiun',
    GMTOffset: 'UTC +01:00',
    GMTOffsetMinute: '+60',
  },
  {
    countryCode: 'YE',
    countryName: 'Yemen',
    timeZone: 'Asia/Aden',
    GMTOffset: 'UTC +03:00',
    GMTOffsetMinute: '+180',
  },
  {
    countryCode: 'ZM',
    countryName: 'Zambia',
    timeZone: 'Africa/Lusaka',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
  {
    countryCode: 'ZW',
    countryName: 'Zimbabwe',
    timeZone: 'Africa/Harare',
    GMTOffset: 'UTC +02:00',
    GMTOffsetMinute: '+120',
  },
];
const LOCAL_MAP: LocalMoment[] = [
  {
    type: 'vn',
    local: 'vi',
  },
  {
    type: 'en',
    local: 'en',
  },
];

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private dateFormatDefault = 'DD/MM/YYYY';
  private dateFormatDefaultPipe = 'dd/MM/yyyy';
  private timeFormatDefault = 'HH:mm';
  private timeZoneDefault: TimeZone;
  private _local: LocalMoment;
  private _dateFormat: string;
  private _dateFormatPipe: string;
  private _timeFormat: string;
  private _timeZone: string;
  private _firstDay: string;
  private _currentUser: User;
  private _currentTimeZone: TimeZone;
  private _id: string;
  public eventChange: EventEmitter<User> = new EventEmitter();

  constructor(
    private store: Store<AppState>,
    private auth: AuthService,
  ) {
    this._dateFormat = this.dateFormatDefault;
    this.init();
  }

  init() {
    this.timeZoneDefault = TIME_ZONES.find(
      (x) => x.timeZone === 'Asia/Ho_Chi_Minh',
    ); // set time zone default
    this._dateFormat = this.dateFormatDefault;
    this._dateFormatPipe = this.dateFormatDefaultPipe;
    this._timeFormat = this.timeFormatDefault;
    this._timeZone = this.timeZoneDefault.GMTOffsetMinute;
    this._firstDay = '1';
    const _this = this;

    const localByLanguage = localStorage.getItem('language');
    const indexLocal = LOCAL_MAP.find((x) => x.type == localByLanguage);
    if (indexLocal) {
      this._local = indexLocal;
    } else {
      this._local = LOCAL_MAP.find((x) => x.type == 'en');
    }

    const user: any = this.store.pipe(select(currentUser));
    if (user) {
      select(
        user
          .pipe(
            tap((data: any) => {
              if (data) {
                // set date format
                _this._currentUser = data;
                this._id = data.id;
                if (
                  data.dateFormat != undefined &&
                  data.dateFormat != null &&
                  data.dateFormat.length > 0
                ) {
                  const indexDate = DATE_FORMAT.find(
                    (x) => x.type === data.dateFormat,
                  );
                  if (indexDate) {
                    _this._dateFormat = indexDate.format;
                    _this._dateFormatPipe = indexDate.pipe;
                  } else {
                    _this._dateFormat =
                      _this.dateFormatDefault;
                    _this._dateFormatPipe =
                      _this.dateFormatDefaultPipe;
                  }
                } else {
                  _this._dateFormat = _this.dateFormatDefault;
                  _this._dateFormatPipe =
                    _this.dateFormatDefaultPipe;
                }

                // check end set time format
                if (
                  data.timeFormat != undefined &&
                  data.timeFormat != null &&
                  data.timeFormat.length > 0
                ) {
                  const indexTime = TIME_FORMAT.find(
                    (x) => x.type === data.timeFormat,
                  );
                  if (indexTime) {
                    _this._timeFormat = indexTime.format;
                  } else {
                    _this._timeFormat =
                      _this.timeFormatDefault;
                  }
                } else {
                  _this._timeFormat = _this.timeFormatDefault;
                }

                // check and set time zone
                if (
                  data.timezone !== undefined &&
                  data.timezone !== null &&
                  data.timezone.length > 0
                ) {
                  const indexTimeZone = TIME_ZONES.find(
                    (x) => x.timeZone == data.timezone
                  );
                  this._currentTimeZone = this.timeZoneDefault;

                  if (indexTimeZone) {
                    _this._timeZone =
                      indexTimeZone.GMTOffsetMinute;
                    this._currentTimeZone = indexTimeZone;
                  } else {
                    _this._timeZone =
                      _this.timeZoneDefault.GMTOffsetMinute;
                  }
                } else {
                  _this._timeZone =
                    _this.timeZoneDefault.GMTOffsetMinute;
                }

                // first day
                if (
                  data.weekFirstDay !== undefined &&
                  WEEKFIRSTDAY.find(
                    (x) => x.id === data.weekFirstDay,
                  )
                ) {
                  this._firstDay = data.weekFirstDay;
                }
              }
            }),
          )
          .subscribe()
          .unsubscribe(),
      );
    }
  }

  setCurrentUser(user: any) {
    if (this._currentUser) {
      Object.assign(this._currentUser, user);
    } else {
      this._currentUser = user;
    }

    // this.store.dispatch(new UserLoaded({user:this._currentUser}))
  }

  reload() {
    // this.store.dispatch(new UserLoaded({user: this._currentUser}));
    this.init();
  }

  private updateUserLogin(user) {
  }

  async getCurrentUser() {
    if (!this._currentUser) {
      this.init();
      await this.auth.profile().toPromise().then((data:any) => {
        this._currentUser = data.data;
        return this._currentUser;
      });  
    }
    return this._currentUser;
  }

  get currentUser() {
    if (!this._currentUser) {
      this.init();
    }
    return this._currentUser;
  }

  get getId() {
		return this._id;
	}

  get dateFormat() {
    return this._dateFormat;
  }

  get currentDate(): DateFormat {
    return DATE_FORMAT.find((x) => x.format == this._dateFormat);
  }

  get currentTime(): TimeFormat {
    return TIME_FORMAT.find((x) => x.format == this._timeFormat);
  }

  get dateFormatPipe() {
    return this._dateFormatPipe;
  }

  get timeFormat() {
    return this._timeFormat;
  }

  get currentTimeZone(): TimeZone {
    return this._currentTimeZone;
  }

  get timeZone() {
    return this._timeZone;
  }

  get local() {
    return this._local;
  }

  get firstDay(): number {
    return Number.parseInt(this._firstDay);
  }

  get listTimeZone() {
    return TIME_ZONES;
  }

  get listLanguage() {
    return LANGUAGE;
  }

  get listWeight() {
    return WEIGHT;
  }

  get listWeekFirst() {
    return WEEKFIRSTDAY;
  }

  get listDecimal() {
    return DECIMAL;
  }

  get listVolume() {
    return VOLUME;
  }

  get listTemperature() {
    return TEMPERATURE;
  }

  get listDistance() {
    return DISTANCE;
  }

  get listTimeFormat() {
    return TIME_FORMAT;
  }

  get listDateFormat() {
    return DATE_FORMAT;
  }

  get listRoles() {
    return ROLES;
  }

  get listUserType() {
    return USERTYPE;
  }

  get listElectricType() {
    return ELECTRIC_TYPE;
  }

  get listChargeType() {
    return CHARGE_TYPE;
  }
}

export class Language {
  key: string;
  name: string;
}

export class TimeZone {
  countryCode: string;
  countryName: string;
  timeZone: string;
  GMTOffset: string;
  GMTOffsetMinute: string;
}

export class DateFormat {
  type: string;
  format: string;
  pipe: string;
  name: string;
  amchart: string;
}

export class TimeFormat {
  type: string;
  format: string;
  name: string;
  amchart: string;
}

export class LocalMoment {
  type: string;
  local: string;
}

export class RelativeTime {
  local: string;
  relativeTime: {};
  future: 'in %s';
  past: '%s ago';
  s: 'day';
  ss: 'day';
  m: 'day';
  mm: 'day';
  h: 'day';
  hh: 'day';
  d: 'a day';
  dd: '%d days';
  M: 'a month';
  MM: '%d months';
  y: 'a year';
  yy: '%d years';
}
