export class IconType {
  name: string;
  key: string;
  icon: string;
  iconExtend: string;

  constructor(object: IconType) {
    return object;
  }
}
