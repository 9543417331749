<nb-card class="modal-card">
    <nb-card-header>
      <div class="d-flex justify-content-between">
        <h5>{{ 'MANAGE.BOOKING.DETAIL_TITLE' | translate }}</h5>
        <button (click)="dialogRef.close()" class="close" nbButton ghost status="basic"><nb-icon
            icon="close-outline"></nb-icon></button>
      </div>
    </nb-card-header>
    <nb-card-body>
          <div class="row" *ngIf="bookingDetail">
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.CONNECTOR_NAME' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.connectorInfo.name}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label class="form-control-file">{{ 'MANAGE.BOOKING.CONNECTOR_TYPE' | translate }}</label>
                <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail?.connectorInfo?.connectorTypeInfo?.name}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label class="form-control-file">{{ 'MANAGE.BOOKING.IMEI' | translate }}</label>
                <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.deviceImei}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.ADDRESS' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.address}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.TIME_SLOT' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.chargeTime}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.CHARGE_TIME' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth
                value="{{bookingDetail.duration}} {{ 'MANAGE.BOOKING.MINUTE' | translate}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.DEPOSIT' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.deposit}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.PROVISIONAL_COSTS' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.totalAmount}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.ELECTRIC_PRICE' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.electricityPrice}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.PARKING_PRICE' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.parkingPrice}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.OCCUPIED_CHARGE_PRICE' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.occupiedChargePrice}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.WATTAGE' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.maximumOutputPower}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.ELECTRIC_TYPE' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.electricType}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.VEHICLE_TYPE' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth
                value="{{ 'COMMON.VEHICLE_TYPE.' + bookingDetail.vehicleType|translate}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.STATUS' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{'MANAGE.BOOKING.' + bookingDetail.status | translate}}">
            </div>
            <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.BOOKED_AT' | translate }}</label>
              <input [readOnly]="true" type="text" nbInput fullWidth value="{{bookingDetail.createdAt}}">
            </div>
          </div>
    </nb-card-body>
  </nb-card>