import {Injectable, Pipe, NgModule} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import * as moment from 'moment';
import {MapConfigModel} from "../model/map-config";
import {UserDatePipe} from "../../@core/layout";
import {SVGUtil} from "./svg-util";
import {MapConfig} from "../config/map.config";

@Injectable({
  providedIn: 'root',
})
export class TrackingUtil {
  private configMap: MapConfigModel;

  constructor(
    private sanitizer: DomSanitizer,
    private userDate: UserDatePipe,
    private svgUtil: SVGUtil
  ) {
    this.configMap = new MapConfig().configs();
  }

  processItems(data, datetimeNow) {
    let _this = this;
    const icons = _this.configMap.icons;
    let dataNew = data.map((x) => {
      let indexIcon = 0;
      if (x['icon']['deviceName']) {
        let indexIconSearch = _this.configMap.icons.findIndex(
          (i) => i.name == x['icon']['deviceName']
        );
        if (indexIconSearch >= 0) {
          indexIcon = indexIconSearch;
        }
      }
      x.iconType = Object.assign({}, icons[indexIcon]);
      x.iconTypeProcess = Object.assign({}, icons[indexIcon]);

      if (x['latitude'] == 'null') x['latitude'] = null;
      if (x['longitude'] == 'null') x['longitude'] = null;
      // x.groupsStr = x.groups
      // 	.map((g) => {
      // 		return g.name;
      // 	})
      // 	.join(', ');
      if (x['timestampUtc']) {
        x['timestampProcess'] = _this.userDate.transform(
          x['timestampUtc'],
          'fromNowDateTimeShort3',
          datetimeNow
        );
      }
      if (
        x['battery']['isAvailable'] &&
        x['battery']['percent'] != undefined
      ) {
        x.batterySvg = _this.sanitizer.bypassSecurityTrustHtml(
          _this.svgUtil.renderBattery(
            x['battery']['percent'],
            x['battery']['isCharged']
          )
        );
      }
      // status_duration
      if (x['statusDuration'] != undefined) {
        if (x['oldStatus'] != x['status']) {
          x['oldTimestampUtc'] = x['timestampUtc'];
          x['statusDuration'] = Math.abs(x['statusDuration']);
          x['statusTimestamp'] =
            moment().utc().unix() - x['statusDuration'];
          x['statusDurationStr'] = _this.userDate.transform(
            datetimeNow,
            'fromNowDateTimeShort',
            null,
            'datetime',
            x['statusDuration']
          );
        }
      }
      // signal
      if (x.gsmLevel != null) {
        let gsmTranslate = 'COMMON.LIST_DEVICE.GSM.LOST_GSM';
        if (x.gsmLevel > 0 && x.gsmLevel <= 25) {
          gsmTranslate = 'COMMON.LIST_DEVICE.GSM.WEAK';
        } else if (x.gsmLevel > 25 && x.gsmLevel <= 50) {
          gsmTranslate = 'COMMON.LIST_DEVICE.GSM.MEDIUM';
        } else if (x.gsmLevel > 50 && x.gsmLevel <= 75) {
          gsmTranslate = 'COMMON.LIST_DEVICE.GSM.GOOD';
        } else if (x.gsmLevel > 75) {
          gsmTranslate = 'COMMON.LIST_DEVICE.GSM.EXCELLENT';
        }
        x.gsmTranslate = gsmTranslate;
      }
      // allow show on map

      x.iconTypeProcess.icon = x.iconTypeProcess.icon
        .replace('viewBox="0 0 34 34"', 'viewBox="0 0 24 24"')
        .replace('viewBox="0 0 48 48"', 'viewBox="0 0 36 36"')
        .replace('transform="rotate({iconRotate})"', '')
        .replace('{iconFill}', '#fff')
        .replace(
          /_1/g,
          '_' + Date.now() + '_' + (Math.random() * 100).toFixed(3)
        )
        .replace(
          /_2/g,
          '_' + Date.now() + '_' + (Math.random() * 100).toFixed(3)
        );
      x.iconTypeProcess.icon = _this.sanitizer.bypassSecurityTrustHtml(
        x.iconTypeProcess.icon
      );
      if (x.feature == undefined) {
        x.feature = {};
      }
      x.otherData = {
        air: '-',
        door: '-',
        engine: '-',
        ben: '-',
        acc: '-'
      };
      x.show = {
        air: 'false',
        door: 'false',
        engine: 'false',
        ben: 'false',
        acc: 'false'
      }
      if (x.basic != undefined) {
        x.basic.forEach((status) => {
          switch (status.type) {
            case 'door':
              x.otherData.door =
                'COMMON.LIST_DEVICE.GENERAL.' +
                (status.value === 1 ? 'OPEN' : 'CLOSED');
              x.show.door = status.isUsed;
              break;
            case 'air':
              x.otherData.air =
                'COMMON.LIST_DEVICE.GENERAL.' +
                (status.value === 1 ? 'ON' : 'OFF');
              x.show.air = status.isUsed;
              break;
            case 'engine':
              x.otherData.engine =
                'COMMON.LIST_DEVICE.GENERAL.' +
                (status.value === 1 ? 'ON' : 'OFF');
              x.show.engine = status.isUsed;
              break;
            case 'ben':
              x.otherData.ben =
                'COMMON.LIST_DEVICE.GENERAL.' +
                (status.value === 1 ? 'ON' : 'OFF');
              x.show.ben = status.isUsed;
              break;
            case 'acc':
              x.otherData.acc =
                'COMMON.LIST_DEVICE.GENERAL.' +
                (status.value === 1 ? 'ON' : 'OFF');
              x.show.acc = status.isUsed;
              break;
          }
        });
      }
      // process geofence

      x = this.processStatus(x);
      return x;
    });
    return dataNew;
  }

   processStatus(item) {
    switch (item.status) {
      case 3:
        item.statusType = this.configMap.status.NotActive;
        break;
      case 4:
        item.statusType = this.configMap.status.Closed;
        break;
      case 1:
        item.statusType = this.configMap.status.Full;
        break;
      case 2:
        item.statusType = this.configMap.status.Maintenance;
        break;
      case 0:
        item.statusType = this.configMap.status.Available;
        break;
      default:
        item.statusType = this.configMap.status.NotActive;
        break;
    }
    return item;
  }
   processStatusConnect(item) {
    switch (item.status) {
      case 1:
        item.statusType = this.configMap.status.InUse;
        break;
      case 2:
        item.statusType = this.configMap.status.Maintenance;
        break;
      case 0:
        item.statusType = this.configMap.status.Available;
        break;
      case 3:
        item.statusType = this.configMap.status.Occupied;
        break;
      default:
        item.statusType = this.configMap.status.Maintenance;
        break;
    }
    return item;
  }
}
