import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { AuthComponent } from './pages/auth/auth.component';
import { AuthGuard } from './@core/auth';
import { LoginComponent } from './pages/auth/login/login.component';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/miscellaneous/not-found/not-found.component';
import { PaymentComponent } from './pages/payment/payment.component';

export const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./pages/pages.module')
  //     .then(m => m.PagesModule),
  // },
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./pages/auth/auth.module')
  //     .then(m => m.AuthModule),
  // },
  // { 
  //   path: '', 
  //   component: AuthComponent
  // },
  // {
  //   path: '',
  //   component: AuthComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: LoginComponent,
  //     },
  //     {
  //       path: 'login',
  //       component: LoginComponent,
  //     },
  //     {
  //       path: 'register',
  //       component: NbRegisterComponent,
  //     },
  //     {
  //       path: 'logout',
  //       component: NbLogoutComponent,
  //     },
  //     {
  //       path: 'request-password',
  //       component: NbRequestPasswordComponent,
  //     },
  //     {
  //       path: 'reset-password',
  //       component: NbResetPasswordComponent,
  //     },
  //   ],
  // },
  // { path: '', redirectTo: 'auth', pathMatch: 'full' },
  // { path: '**', component: NotFoundComponent, pathMatch: 'full' },
  {path: 'payment', loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)},
  {path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)},

	// enable this router to set which demo theme to load,
	{path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)},

	{path: '**', component: NotFoundComponent, pathMatch: 'full'},
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
