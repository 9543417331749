import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TypesUtilsService } from '../../@core/models';
import { BaseOptionAPI } from '../../@core/models/query-models/_base.option-api';
import { Response } from '../../@core/models/query-models/_new-response-body.model';

const API_URL = environment.api.host + "/notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationPopUpService {

  constructor(private http: HttpClient, private utils: TypesUtilsService) { }
  search(option?: any, params?: any): Observable<Response> { 
    return this.http.post<Response>(API_URL + '/search', option, {params : params});
  }

  updateStatus(id : number): Observable<Response> { 
    return this.http.put<Response>(`${API_URL}/is-read/${id}`, {});
  }

}

