<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><span>{{title}}</span></a>
  </div>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item">
      <ngx-popup-notification [listMessage]="listMessage" [idUser]="idUser" [pageToLoadNext]="pageToLoadNext + 1" style="width: 20px"></ngx-popup-notification>
    </nb-action>
    <nb-action class="control-item">
      <ngx-language-selector style="width: 20px"></ngx-language-selector>
    </nb-action>
 


    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture"
               [title]="user?.walletBalanceStr"
               size="large"
               color="rgb(241, 196, 15)">
      </nb-user>
    </nb-action>
    <!-- <nb-action class="user-action">
      <kt-user-profile></kt-user-profile>
    </nb-action> -->
  </nb-actions>
</div>
