export class MapFeature {
  mapType?: { show: boolean, value: string, translate: string, cache: boolean };
  zoomIn?: { show: boolean, value: string, translate: string };
  zoomOut?: { show: boolean, value: string, translate: string };
  location?: { show: boolean, value: boolean, translate: string };
  traffic?: { show: boolean, value: boolean, translate: string };
  fitbound?: { show: boolean, value: string, translate: string };
  cluster?: {
    show: boolean, value: boolean, translate: string, options: {
      showSpeed: boolean,
      showDuration: boolean,
    },
    cache: boolean
  };
  markerStyle?: {
    show: boolean, value: string, types: Array<{ name: string, translate: string, icon: string }>, options: {
      showSpeed: boolean,
      showDuration: boolean,
    },
    cache: boolean
  };
  help?: boolean;
  geofence?: { show: boolean, value: boolean, translate: string };
  landmark?: { show: boolean, value: boolean, translate: string };
  createTool?: { show: boolean, value: boolean, translate?: string };
  direction?: { show: boolean, value: boolean, translate?: string };
  colorHint?: { show: boolean, value: boolean, translate: string, cache: boolean }
  markerPopup?: { show: boolean, value: boolean, translate: string, cache: boolean };
  centerPoint?: { show: boolean, value: boolean, translate: string, cache: boolean };
  follow?: { show: boolean, value: boolean, translate: string, maxTail: number };
  fullScreen?: { show: boolean, value: boolean, translate: string, translateOff: string };
  brightness?: { show: boolean, value: number, translate: string, cache: boolean };

  constructor(object?: MapFeature) {
    this.mapType = {show: true, value: "roadmap", translate: "MAP.FEATURES.MAP_TYPE", cache: true};
    this.zoomIn = {show: true, value: "", translate: "MAP.FEATURES.ZOOM_IN"};
    this.zoomOut = {show: true, value: "", translate: "MAP.FEATURES.ZOOM_OUT"};
    this.location = {show: true, value: false, translate: "MAP.FEATURES.LOCATION"};
    this.traffic = {show: false, value: false, translate: "MAP.FEATURES.TRAFFIC"};
    this.fitbound = {show: true, value: "", translate: "MAP.FEATURES.FITBOUND"};

    this.cluster = {
      show: true, value: false, translate: "MAP.FEATURES.CLUSTER", options: {
        showSpeed: false,
        showDuration: false,
      },
      cache: true
    };
    this.geofence = {show: true, value: false, translate: "MAP.FEATURES.GEOFENCES"};
    this.createTool = {show: true, value: false};
    this.direction = {show: true, value: false};
    this.landmark = {show: true, value: false, translate: "MAP.FEATURES.LANDMARK"};
    this.colorHint = {show: true, value: true, translate: "MAP.FEATURES.MARKER_POPUP", cache: true};
    this.follow = {show: false, value: false, translate: "MAP.FEATURES.FOLLOW", maxTail: 20};
    this.markerPopup = {show: false, value: true, translate: "MAP.FEATURES.MARKER_POPUP", cache: true};
    this.centerPoint = {show: true, value: false, translate: "MAP.FEATURES.CENTER_POINT", cache: false};
    this.markerStyle = {
      show: true,
      value: 'style-two',
      types: [
        {
          name: 'style-one',
          translate: 'MAP.FEATURES.STYLE_MARKER_ONE',
          icon: 'icon-tags',
        },
        {
          name: 'style-two',
          translate: 'MAP.FEATURES.STYLE_MARKER_TWO',
          icon: 'icon-tags',
        },
      ],
      options: {
        showSpeed: false,
        showDuration: false,
      },
      cache: true,
    };
    this.help = false;
    this.fullScreen = {
      show: false,
      value: false,
      translate: "MAP.FEATURES.FULL_SCREEN",
      translateOff: "MAP.FEATURES.NORMAL_SCREEN",
    };
    this.brightness = {show: true, value: 100, translate: "MAP.FEATURES.BRIGHTNESS", cache: true};
    if (object)
      return Object.assign(this, object);
    return this;
  }
}
