import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SVGUtil {

  renderBattery(percent: number, changed: boolean) {
    // const changedStr = `
    // <path d="m158.612 76.1a8 8 0 0 0 -6.983-4.1h-47.838l8.193-62.6a8 8 0 0 0 -14.747-5.232l-63.728
    // 103.642a8 8 0 0 0 6.815 12.19h47.052l-7.363 62.7a8 8 0 0 0 14.762 5.121l63.671-103.63a8 8 0 0 0
    // .166-8.091zm-58.476 72.433 4.18-35.595a8 8 0 0 0 -7.945-8.938h-41.736l36.628-59.569-4.519 34.531a8 8 0 0 0 7.932 9.038h42.649z"/>
    // `;
    const changedStr = `
        <polygon fill="#fff" stroke="#65B300" stroke-width="5" stroke-miterlimit="10" points="58.807,16.452 38.786,16.452
	43.881,-7.303 16.526,26.548 36.551,26.548 31.456,50.303 "/>
        `;

    let percentConvert = (percent / 100) * 71;
    const svg = `<svg xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" width="30px" height="20px" viewBox="0 0 100 50">
            <g>
                <polygon fill="#65B300" stroke="#65B300" stroke-width="2" points="80.854,29.644 75.463,29.644 75.463,26.952 78.158,26.952 78.158,16.169 75.463,16.169
            75.463,13.475 80.854,13.475 	" />
                <path fill="#65B300" d="M70.793,0H4.672C2.092,0,0,2.095,0,4.67v33.784c0,2.574,2.092,4.666,4.672,4.666h66.121
            c2.574,0,4.67-2.092,4.67-4.666v-8.81V13.475V4.67C75.463,2.095,73.367,0,70.793,0z M70.793,2.693c1.09,0,1.977,0.886,1.977,1.977
            v8.805v16.169v8.81c0,1.092-0.887,1.973-1.977,1.973H4.672c-1.092,0-1.98-0.881-1.98-1.973V4.67c0-1.091,0.889-1.977,1.98-1.977
            H70.793z" />
                <path fill="none"
                    d="M4.672,2.693c-1.092,0-1.98,0.886-1.98,1.979v33.786c0,1.088,0.889,1.969,1.98,1.969h66.121
            c1.09,0,1.977-0.881,1.977-1.969v-8.814V13.475V4.672c0-1.093-0.887-1.979-1.977-1.979H4.672z" />
                <rect width="{percent}" height="37" stroke="#fff"  fill="#65B300"
                    stroke-width="1" x="2" y="3" rx="3" ry="3">
                </rect>
            </g>
            {changed}
        </svg>`;

    if (changed) {
      return svg.replace("{percent}", percentConvert.toString()).replace("{changed}", changedStr);
    } else {
      return svg.replace("{percent}", percentConvert.toString()).replace("{changed}", "");
    }
  }
}
