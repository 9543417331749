export class MapType {
  type: string;
  layer: object;
  translate: string;
  icon: string;
  allowCache?: boolean;

  constructor(object: MapType) {
    this.type = "";
    return object;
  }

}
