<nb-layout>
  <nb-layout-column>
    <nb-card class="modal-card" style="height: 100%;">
      <nb-card-header>
        <div class="d-flex justify-content-between">
          <!-- <h5 *ngIf="!qrCodePage">{{ 'MAP.LABEL.choose_evcharge' | translate }} {{timeNow}}</h5> -->
          <h5 *ngIf="!qrCodePage">{{deviceInfo?.deviceName}}</h5>
          <h5 *ngIf="qrCodePage">{{ 'MAP.LABEL.payment_info' | translate}}</h5>
          <button [nbTooltip]="'COMMON.ACTIONS.BACK' | translate " *ngIf="qrCodePage" (click)="goToChooseEvcharge()" nbButton status="basic"><nb-icon [icon]="'undo-outline'"></nb-icon></button>
        </div>
      </nb-card-header>
      <nb-card-body>
        <!-- <nb-stepper #stepper disableStepNavigation (stepChange)="handleStepChange($event)">
          <nb-step label="{{ 'MANAGE.BOOKING.INFO' | translate }}"> -->
        <form *ngIf="!qrCodePage" [formGroup]="saveForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="form-group col-lg-12 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.CHOOSE_CONNECTOR' | translate }} <span
                class="text-danger">*</span></label>
              <div class="container" style="margin: unset">
                <div (click)="chooseConnector(plus)" *ngFor="let plus of connectors"
                     class="text-center d-flex flex-column justify-content-center align-items-center connector-row"
                     style="padding: 3px;cursor: pointer; margin:0px 5px 5px 0px;"
                     [style.pointer-events]="plus.disable == true ? 'none' : ''"
                     >
                     <nb-card style="margin-bottom: 5px;width: 100%;" class="connect-type">
                      <nb-card-header [style.background-color]="plus?.statusType?.colorCode">
                        {{ plus.connectorInfo?.name }}
                      </nb-card-header>
                      <nb-card-body style="padding: 10px 5px 5px 5px;overflow: hidden;display: flex;justify-content: left;align-items: center;flex-direction: column"
                       [ngClass]="{'choose-connector-type' : plus.connectorId === connectorId}">
                        <div class="row" style="width: 100%;">
                          <div class="column" style="width: 33.33%;text-align: center;">
                            <img style="height: 30px" [src]="plus?.connectorInfo?.connectorTypeInfo?.image" alt="">
                          </div>
                          <div class="column" style="width: 66.67%;text-align: center;">
                            <div>
                              {{plus?.statusType?.text|translate}}
                            </div>
                            {{getTimeDiff(plus?.lastStatusTime, plus?.lastMeterTime)}}
                          </div>
                        </div>
                      </nb-card-body>
                      <nb-card-footer style="padding: 5px;text-align: center" [ngClass]="{'choose-connector-type' : plus.connectorId === connectorId}">
                        {{getRound(plus?.lastMeter)}} kWh | {{plus?.batteryStatus}}%
                      </nb-card-footer>
                     </nb-card>
                  <!-- <span style="font-size: 12px">{{plus.connectorName}}</span>
                  <img style="width: 42px" [src]="plus?.connectorInfo?.connectorTypeInfo?.image" alt="">
                  <span style="font-size: 12px;margin-top: 2px;"
                        [ngStyle]="{color: plus.statusType.colorCode}">{{plus?.statusType?.text|translate}}</span> -->
                </div>
              </div>
              <ngx-inline-message [message]="'MANAGE.BOOKING.CONNECTOR' | translate"
                                  [formName]="saveForm.controls.connectorId"></ngx-inline-message>
            </div>
            <!-- <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.PICK_DATE' | translate }} <span
                  class="text-danger">*</span></label>
              <input nbInput fullWidth placeholder="{{'MANAGE.BOOKING.PICK_DATE' | translate}}"
                [nbDatepicker]="dateTimePicker" formControlName="date">
              <nb-datepicker #dateTimePicker [min]="min" (dateChange)="getPeriodTime($event)"></nb-datepicker>
            </div> -->
            <!-- <div class="form-group col-lg-6 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.CHARGING_HOURS' | translate }} <span
                class="text-danger">*</span></label>
              <nb-select fullWidth formControlName="hour" (selectedChange)="getPeriodTime()">
                <nb-option [value]="1">1 {{'MANAGE.BOOKING.HOUR' | translate}}</nb-option>
                <nb-option [value]="2">2 {{'MANAGE.BOOKING.HOUR' | translate}}</nb-option>
                <nb-option [value]="3">3 {{'MANAGE.BOOKING.HOUR' | translate}}</nb-option>
                <nb-option [value]="4">4 {{'MANAGE.BOOKING.HOUR' | translate}}</nb-option>
                <nb-option [value]="5">5 {{'MANAGE.BOOKING.HOUR' | translate}}</nb-option>
                <nb-option [value]="6">6 {{'MANAGE.BOOKING.HOUR' | translate}}</nb-option>
                <nb-option [value]="12">12 {{'MANAGE.BOOKING.HOUR' | translate}}</nb-option>
              </nb-select>
            </div> -->
            <!-- <div class="form-group col-lg-12 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.CHOOSE_TIME_FRAME' | translate }} <span
                class="text-danger">*</span></label>
              <div class="container-frame-time" style="margin: unset">
                <div (click)="chooseFrameTime(time)" *ngFor="let time of listPeriodTime"
                     class="text-center d-flex flex-column justify-content-center align-items-center connect-type frame-time"
                     [style.pointer-events]="time.booked == true ? 'none' : ''"
                     [ngStyle]="{'background-color': time.booked == true ? 'var(--color-info-200)' : 'none'}"
                     [ngClass]="{'choose-frame-time' : time.id === frameTimeIndex}">
                  <span style="font-size: 12px">{{time.start}} - {{time.end}}</span>
                </div>
              </div>
              <ngx-inline-message [message]="'MANAGE.BOOKING.TIME_SLOT' | translate"
                                  [formName]="saveForm.controls.timeFrameId"></ngx-inline-message>
            </div> -->
            <div class="form-group col-lg-3 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.START_TIME' | translate }}</label>
              <input nbInput fullWidth type="text" [nbTimepicker]="timepickerStart" formControlName="startTime"/>
              <nb-timepicker #timepickerStart (onSelectTime)="handleTime($event, true)"></nb-timepicker>
            </div>
            <div class="form-group col-lg-3 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.END_TIME' | translate }}</label>
              <input nbInput fullWidth type="text" [nbTimepicker]="timepickerEnd" formControlName="endTime"/>
              <nb-timepicker #timepickerEnd (onSelectTime)="handleTime($event, false)"></nb-timepicker>
            </div>
            <div class="form-group col-lg-3 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.CHARGE_TIME' | translate }}</label>
              <input nbInput fullWidth type="text" [readOnly]="true" value="{{chargeTime}} {{'MANAGE.BOOKING.MINUTE' | translate}}"/>
            </div>
            <div class="form-group col-lg-3 col-md-12">
              <label class="form-control-file">{{ 'MANAGE.BOOKING.ELECTRIC_PRICE' | translate }}</label>
              <input nbInput fullWidth type="text" [readOnly]="true" value="{{deviceInfo?.electricityPriceFormat}}"/>
            </div>
            <h5 class="form-group col-lg-12 col-md-12 font-weight-bold">
              {{'MANAGE.BOOKING.COST' | translate}} {{cost}} VNĐ
            </h5>
          </div>
          <div style="display: flex;justify-content: center;">
            <button type="submit" nbButton [disabled]="saveForm.invalid">{{
              'MANAGE.BOOKING.CONFIRM' | translate }}</button>
          </div>

        </form>
        <!-- </nb-step>
        <nb-step> -->
        <iframe *ngIf="qrCodePage" style="width: 100%;height: 100%;" [src]="qrLink" title="QRCODE"></iframe>
        <!-- <button nbButton nbStepperPrevious>{{ 'MANAGE.BOOKING.PREVIOUS' | translate }}</button>
        <button nbButton (click)="stepper.reset()">{{ 'MANAGE.BOOKING.NEW' | translate }}</button> -->
        <!-- </nb-step>
      </nb-stepper> -->
      </nb-card-body>
    </nb-card>  </nb-layout-column>
</nb-layout>

