import * as L from 'leaflet';
import {MapConfigModel} from "../model/map-config";
import {StatusType} from '../model/status-type';
import {IconType} from "../model/icon-type";
import {MapFeature} from "../model/map-feature";
import {MapOption} from "../model/map-option";
import {MapType} from "../model/map-type";

export class MapConfig {
  public defaults: MapConfigModel = {
    status: {
      Available: new StatusType({
        text: 'MAP.STATUS.Available',
        icon: 'icon-circle1',
        color: 'white',
        colorCode: '#46af61',
        colorCircleCode: '#457215',
        key: '0',
        name: 'Available'
      }),
      InUse: new StatusType({
        text: 'MAP.STATUS.InUse',
        icon: 'icon-circle1',
        color: 'black',
        colorCode: '#FF8F8F',
        colorCircleCode: '#915e0d',
        key: '1',
        name: 'InUse'
      }),
      Full: new StatusType({
        text: 'MAP.STATUS.Full',
        icon: 'icon-circle1',
        color: 'white',
        colorCode: '#ff0000',
        colorCircleCode: '#990000',
        key: '2',
        name: 'Full'
      }),
      Repair: new StatusType({
        text: 'MAP.STATUS.Repair',
        icon: 'icon-circle1',
        color: 'white',
        colorCode: '#054efa',
        colorCircleCode: '#002989',
        key: '3',
        name: 'Repair'
      }),
      NotActive: new StatusType({
        text: 'MAP.STATUS.NotActive',
        icon: 'icon-circle1',
        color: 'black',
        colorCode: '#d8d8d8',
        colorCircleCode: '#626262',
        key: '4',
        name: 'NotActive'
      }),
      Closed: new StatusType({
        text: 'MAP.STATUS.Closed',
        icon: 'icon-circle1',
        color: 'white',
        colorCode: '#054efa',
        colorCircleCode: '#002989',
        key: '1',
        name: 'Closed'
      }),
      Break: new StatusType({
        text: 'MAP.STATUS.Break',
        icon: 'icon-circle1',
        color: 'white',
        colorCode: '#000000',
        colorCircleCode: '#656464',
        key: '1',
        name: 'Closed'
      }),
      Maintenance: new StatusType({
        text: 'MAP.STATUS.Maintenance',
        icon: 'icon-circle1',
        color: 'white',
        colorCode: '#ff9e00',
        colorCircleCode: '#915e0d',
        key: '1',
        name: 'Closed'
      }),
      Occupied: new StatusType({
        text: 'MAP.STATUS.Occupied',
        icon: 'icon-circle1',
        color: 'white',
        colorCode: '#054efa',
        colorCircleCode: '#915e0d',
        key: '1',
        name: 'Closed'
      })
    },
    icons: [
      // car
      new IconType({
        icon: `<svg x="3" y="2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24"
                viewBox="0 0 34 34" transform="rotate({iconRotate})" fill="{iconFill}">
                <g fill="none" fill-rule="evenodd" transform="translate(3 4)">
                    <g fill="#495156" >
                        <path
                            d="M2 6.833l1.5-4.5h11l1.5 4.5H2zm12.5 5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm-11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm12.42-9.99c-.2-.59-.76-1.01-1.42-1.01h-11c-.66 0-1.21.42-1.42 1.01L0 7.833v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99z">
                        </path>
                    </g>
                </g>
            </svg>`, iconExtend: `
            <svg width="48" x="{xCar}" y="30" height="48" viewBox="0 0 48 48">
                <g transform="rotate({iconRotate} 24 24)">
                <path fill="{mapIconColor}" d="M33.041,37.5c0,5.247-4.048,9.5-9.041,9.5l0,0c-4.993,0-9.041-4.253-9.041-9.5v-27
                C14.959,5.253,19.007,1,24,1l0,0c4.993,0,9.041,4.253,9.041,9.5V37.5z" />
                <g>
                    <g>
                        <path fill="{bgCarSub}" d="M24,22c0,0-4,0-6,1c-1-3-2-6-2-6s3-4,8-4C24,17,24,22,24,22z" />
                        <path fill="{bgCarSub}" d="M24,22c0,0,4,0,6,1c1-3,2-6,2-6s-3-4-8-4C24,17,24,22,24,22z" />
                    </g>
                    <g>
                        <path fill="{bgCarSub}" d="M24,35c0,0-4,0-5-1s-3,0-3,1s1,3,3,4s5,1,5,1V35z" />
                        <path fill="{bgCarSub}" d="M24,35c0,0,4,0,5-1s3,0,3,1s-1,3-3,4s-5,1-5,1V35z" />
                    </g>
                </g>
                <g>
                    <polygon fill="{bgCarSub}" points="17.842,24.151 15.959,23 15.959,33.355 17.842,33.355 	" />
                    <polygon fill="{bgCarSub}" points="30.158,24.151 32.041,23 32.041,33.355 30.158,33.355 	" />
                </g>
                <g>
                    <path fill="{bgCarSub}" d="M28.379,46.159c0,0-1.567-0.27-0.918-0.918c0.918-0.918,3.672-4.59,4.59-3.672
                    C33.887,43.405,28.379,46.159,28.379,46.159z" />
                    <path fill="{bgCarSub}" d="M19.621,46.159c0,0,1.567-0.27,0.918-0.918c-0.918-0.918-3.672-4.59-4.59-3.672
                    C14.113,43.405,19.621,46.159,19.621,46.159z" />
                </g>
                <path fill="{bgCarSub}" d="M19,46c0,0,5.556,2,10,0c0-2,0-2,0-2s-4.444,2-10,0C19,46,19,46,19,46z" />
                <g>
                    <path d="M32,11c0,0-2.896-7-6.896-7c-0.615,0,1.643,0.22,3,1C29.868,6.015,32,11,32,11z" />
                    <path d="M16,11c0,0,2.896-7,6.896-7c0.615,0-1.643,0.22-3,1C18.132,6.015,16,11,16,11z" />
                </g>
                <g>
                    <path  fill="{bgCarSub}" d="M15.06,17.036c0.575-0.1,0.394,1.024,0,1.28c-0.392,0.253-2.707,0.763-2.707,0.763
                    S12.354,17.496,15.06,17.036z" />
                    <path  fill="{bgCarSub}" d="M32.939,16.92c-0.575-0.099-0.394,1.022,0.003,1.279c0.389,0.254,2.704,0.763,2.704,0.763
                    S35.646,17.379,32.939,16.92z" />
                </g>
            </g>
            <svg>`, key: "1", name: "car"
      }),
      // direction
      new IconType({
        icon: `<svg x="3" y="2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24"
                viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                    <g fill="#495156">
                        <path d="M30.148,35.762L18,29.807L5.852,35.762c-1.862,0.914-3.857-0.964-3.063-2.878L15.931,1.38
                            c0.766-1.84,3.372-1.84,4.145,0l13.135,31.503C34.006,34.791,32.01,36.676,30.148,35.762z"/>
                    </g>
                </g>
            </svg>`,
        iconExtend: `
            <svg width="48" x="{xCar}" y="30" height="48" viewBox="0 0 48 48" fill="{mapIconColor}">
                <g transform="rotate({iconRotate} 24 24)">
                    <path d="M30.148,35.762L18,29.807L5.852,35.762c-1.862,0.914-3.857-0.964-3.063-2.878L15.931,1.38
                    c0.766-1.84,3.372-1.84,4.145,0l13.135,31.503C34.006,34.791,32.01,36.676,30.148,35.762z"/>
                <g>
            </svg>`, key: "2", name: "direction"
      }),
      // container
      new IconType({
        icon: `<svg x="3" y="2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24"
                viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                    <g fill="#495156">
                        <path  d="M1.263,22.207V6.891c0-0.851,0.69-1.541,1.541-1.541h15.309c0.852,0,1.541,0.69,1.541,1.541v15.316
                            c0,0.283-0.23,0.514-0.514,0.514H1.777C1.493,22.721,1.263,22.49,1.263,22.207z"/>
                        <path d="M7.676,24.258H0.514C0.23,24.258,0,24.488,0,24.771v1.559c0,0.283,0.23,0.514,0.514,0.514h5.741
                            C6.405,25.815,6.924,24.906,7.676,24.258z"/>
                        <path  d="M35.486,24.258h-1.25v-6.98c0-0.82-0.245-1.621-0.703-2.3l-3.127-4.63c-0.764-1.132-2.041-1.81-3.406-1.81
                            h-4.85c-0.567,0-1.027,0.46-1.027,1.027v14.693h-7.941c0.752,0.648,1.271,1.558,1.421,2.586h8.375
                            c0.297-2.04,2.053-3.613,4.174-3.613s3.877,1.573,4.175,3.613h4.159c0.283,0,0.514-0.23,0.514-0.514v-1.559
                            C36,24.488,35.77,24.258,35.486,24.258z M30.369,15.905h-6.4c-0.283,0-0.514-0.229-0.514-0.514v-3.552
                            c0-0.284,0.23-0.514,0.514-0.514h3.9c0.166,0,0.323,0.081,0.42,0.218l2.5,3.553C31.028,15.437,30.785,15.905,30.369,15.905z"/>
                            <path  d="M10.429,24.258c-1.765,0-3.196,1.432-3.196,3.196c0,1.766,1.431,3.196,3.196,3.196s3.196-1.431,3.196-3.196
                            C13.625,25.689,12.194,24.258,10.429,24.258z M10.429,29.053c-0.883,0-1.598-0.716-1.598-1.599c0-0.882,0.715-1.599,1.598-1.599
                            s1.598,0.717,1.598,1.599C12.027,28.337,11.312,29.053,10.429,29.053z"/>
                        <path  d="M27.152,24.258c-1.765,0-3.195,1.432-3.195,3.196c0,1.766,1.431,3.196,3.195,3.196
                            c1.766,0,3.197-1.431,3.197-3.196C30.35,25.689,28.918,24.258,27.152,24.258z M27.152,29.053c-0.882,0-1.598-0.716-1.598-1.599
                            c0-0.882,0.715-1.599,1.598-1.599s1.599,0.717,1.599,1.599C28.751,28.337,28.035,29.053,27.152,29.053z"/>
                    </g>
                </g>
            </svg>
                `,
        iconExtend: `<svg width="48" x="{xCar}" y="30" height="48" viewBox="0 0 48 48" fill="{mapIconColor}">
            <g transform="rotate({iconRotate} 24 24)">
                <g>
                    <g>
                        <rect x="15.87" y="8.602"  stroke="#000000" stroke-width="0.3382" stroke-miterlimit="10" width="0" height="1.913"/>
                        <rect x="13" y="9.08"  width="1.913" height="0.957"/>
                    </g>
                    <g>
                        <rect x="32.13" y="8.602"  stroke="#000000" stroke-width="0.3382" stroke-miterlimit="10" width="0" height="1.913"/>
                        <rect x="33.087" y="9.08" width="1.913" height="0.957"/>
                    </g>
                </g>
                <path d="M31.854,15c-4.531,0-7.531,0-15.531,0l-0.176-7.619C16.146,3.857,19.663,1,24,1l0,0
                    c4.337,0,7.853,2.857,7.853,6.381V15z"/>
                <rect x="16.024" y="16.829" width="15.951" height="30.171"/>
                <g>
                    <path fill="{bgCarSub}" d="M18.628,15l-1.859,0.585V6.61c0,0,2.41-2.244,7.231-2.244c0,2.244,0,5.61,0,5.61S19.352,9.848,18.815,11
                            C18.396,11.902,18.628,15,18.628,15z"/>
                    <path  fill="{bgCarSub}" d="M29.37,15l1.861,0.585V6.61c0,0-2.412-2.244-7.231-2.244c0,2.244,0,5.61,0,5.61S28.647,9.848,29.185,11
                            C29.604,11.902,29.37,15,29.37,15z"/>
                </g>
                <g>
                    <g>
                        <path  d="M24,14.003h-4.5c0,0,0-2.304,0-4.167c2.02-0.932,3.49-0.833,4.5-0.833C24,12.729,24,14.003,24,14.003z"/>
                    </g>
                    <g>
                        <path d="M24,14.003h4.5c0,0,0-2.304,0-4.167c-2.018-0.932-3.49-0.833-4.5-0.833C24,12.729,24,14.003,24,14.003z"
                            />
                    </g>
                </g>
                <rect x="18.391" y="15.038" width="11.219" height="1.962"/>
            </g>
            </svg>`, key: "3", name: "container"
      }),
      // truck
      new IconType({
        icon: `<svg x="3" y="2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24"
                viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                    <g fill="#495156">
                        <path d="M36,18.293V16.89l-1.4-6.163c0-0.427-0.619-0.427-0.855-0.427h-7.396c-0.236,0-0.428,0.045-0.428,0.427
                            v11.224c0,1.119,0.191,1.311,0.428,1.311h1.508c0.475-1.042,1.523-1.768,2.741-1.768c1.217,0,2.266,0.727,2.739,1.768h2.236
                            c0.236,0,0.428-0.191,0.428-0.428v-2.158h-0.402v-2.383H36z M29.215,16.882h-1.617v-0.329h1.617V16.882z M27.273,16.029V11.44
                            h6.178l0.705,4.588H27.273z M34.451,22.264h-1.146c-0.324-0.617-1.031-1.059-1.031-1.059h2.178V22.264z"/>
                        <path  d="M1.496,20.795h23.397v2.68H12.494c-0.421-1.154-1.529-1.98-2.828-1.98s-2.406,0.826-2.827,1.98H6.563
                            c-0.421-1.154-1.529-1.98-2.828-1.98c-1.278,0-2.373,0.802-2.807,1.93C0.393,23.27,0,22.777,0,22.191v-0.113
                            c0-0.291,0.1-0.554,0.262-0.769v-1.047L0.249,9.559h24.271l-0.024,10.705h-23V20.795z"/>
                            <path d="M3.635,21.965c-1.234,0-2.238,1.004-2.238,2.237c0,1.234,1.004,2.239,2.238,2.239s2.238-1.005,2.238-2.239
                            C5.873,22.969,4.869,21.965,3.635,21.965z M3.635,25.756c-0.856,0-1.554-0.696-1.554-1.554c0-0.856,0.696-1.554,1.554-1.554
                            s1.555,0.697,1.555,1.554C5.189,25.06,4.492,25.756,3.635,25.756z"/>
                        <path  d="M30.496,21.965c-1.234,0-2.238,1.004-2.238,2.237c0,1.234,1.004,2.239,2.238,2.239s2.238-1.005,2.238-2.239
                            C32.734,22.969,31.73,21.965,30.496,21.965z M30.496,25.756c-0.856,0-1.553-0.696-1.553-1.554c0-0.856,0.695-1.554,1.553-1.554
                            s1.555,0.697,1.555,1.554C32.051,25.06,31.354,25.756,30.496,25.756z"/>
                        <path d="M9.566,21.965c-1.234,0-2.238,1.004-2.238,2.237c0,1.234,1.004,2.239,2.238,2.239s2.238-1.005,2.238-2.239
                            C11.805,22.969,10.801,21.965,9.566,21.965z M9.566,25.756c-0.857,0-1.555-0.696-1.555-1.554c0-0.856,0.697-1.554,1.555-1.554
                            c0.856,0,1.554,0.697,1.554,1.554C11.12,25.06,10.423,25.756,9.566,25.756z"/>
                    </g>
                </g>
            </svg>
            `, iconExtend: `
            <svg width="48" x="{xCar}" y="30" height="48" viewBox="0 0 48 48" fill="{mapIconColor}">
                <g transform="rotate({iconRotate} 24 24)">
                <g>
                        <path  d="M31.854,19.755c0,4.029-3.516,7.293-7.854,7.293l0,0c-4.338,0-7.854-3.265-7.854-7.293V8.293
                            C16.146,4.266,19.662,1,24,1l0,0c4.338,0,7.854,3.266,7.854,7.293V19.755z"/>
                        <rect x="15.024" y="17.829" width="17.951" height="29.171"/>
                    </g>
                    <g>
                        <g>
                            <path fill="{bgCarSub}" d="M18.628,15l-1.86,0.585V6.61c0,0,2.411-2.244,7.231-2.244c0,2.244,0,5.61,0,5.61S19.352,9.848,18.815,11
                                C18.396,11.902,18.628,15,18.628,15z"/>
                        </g>
                        <g>
                            <path fill="{bgCarSub}" d="M29.371,15l1.86,0.585V6.61c0,0-2.411-2.244-7.231-2.244c0,2.244,0,5.61,0,5.61S28.648,9.848,29.185,11
                                C29.604,11.902,29.371,15,29.371,15z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M24,15h-4.5c0,0,0-2.304,0-4.167C21.519,9.901,22.99,10,24,10C24,13.726,24,15,24,15z"/>
                        </g>
                        <g>
                            <path d="M24,15h4.5c0,0,0-2.304,0-4.167C26.481,9.901,25.01,10,24,10C24,13.726,24,15,24,15z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path  d="M17.286,11.035c0,0-3.014-0.329-3.776,0.92C13.16,12.527,17.286,11.035,17.286,11.035"/>
                        </g>
                        <g>
                            <path d="M30.714,11.035c0,0,3.014-0.329,3.776,0.92C34.84,12.527,30.714,11.035,30.714,11.035"/>
                        </g>
                    </g>
                    <rect x="18.391" y="15.787" width="11.219" height="1.962"/>
                </g>
                </svg>`, key: "3", name: "truck"
      }),
      // other
      new IconType({
        icon: `<svg x="3" y="2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24"
                viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                    <g fill="#495156" >
                        <path d="M18,0C8.074,0,0,8.075,0,18c0,9.925,8.075,18,18,18c9.925,0,18-8.074,18-18C36,8.075,27.925,0,18,0z
                                M18,33.129C9.657,33.129,2.871,26.342,2.871,18C2.871,9.658,9.657,2.871,18,2.871c8.342,0,15.129,6.787,15.129,15.129
                            C33.129,26.342,26.342,33.129,18,33.129z"/>
                        <path d="M18,9.731c-4.559,0-8.268,3.71-8.268,8.269s3.709,8.269,8.268,8.269c4.559,0,8.268-3.71,8.268-8.269
                            C26.268,13.441,22.559,9.731,18,9.731z M18,23.398c-2.976,0-5.397-2.422-5.397-5.398c0-2.976,2.421-5.397,5.397-5.397
                            c2.977,0,5.398,2.421,5.398,5.397C23.397,20.977,20.977,23.398,18,23.398z"/>
                        <circle cx="18" cy="18" r="3.106"/>
                    </g>
                </g>
            </svg>`, iconExtend:

          `<svg width="48" x="{xCar}" y="30" height="48" viewBox="0 0 48 48" fill="{mapIconColor}">
                <g transform="rotate({iconRotate} 24 24)">
                    <path  d="M18,0C8.074,0,0,8.075,0,18c0,9.925,8.075,18,18,18c9.925,0,18-8.074,18-18C36,8.075,27.925,0,18,0z
                        M18,33.129C9.657,33.129,2.871,26.342,2.871,18C2.871,9.658,9.657,2.871,18,2.871c8.342,0,15.129,6.787,15.129,15.129
                        C33.129,26.342,26.342,33.129,18,33.129z"/>
                    <path d="M18,9.731c-4.559,0-8.268,3.71-8.268,8.269s3.709,8.269,8.268,8.269c4.559,0,8.268-3.71,8.268-8.269
                        C26.268,13.441,22.559,9.731,18,9.731z M18,23.398c-2.976,0-5.397-2.422-5.397-5.398c0-2.976,2.421-5.397,5.397-5.397
                        c2.977,0,5.398,2.421,5.398,5.397C23.397,20.977,20.977,23.398,18,23.398z"/>
                    <circle " cx="18" cy="18" r="3.106"/>
                <g>
            </svg>`,
        key: "4", name: "other"
      }),
      // motorbike
      new IconType({
        icon: `<svg x="3" y="2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24"
                viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                    <g fill="#495156">
                        <path d="M29.369,3.947h-6.08C22.579,1.658,20.447,0,17.921,0s-4.658,1.658-5.368,3.947h-6
                            c-0.869,0-1.579,0.71-1.579,1.579c0,0.868,0.71,1.579,1.579,1.579h6.079l0,0c0.395,1.342,1.263,2.447,2.448,3.158
                            c-4.263,1.263-7.421,5.21-7.421,9.869v8.289c0,1.421,1.185,2.605,2.605,2.605h2.131v-4.5c0-3.079,2.526-5.605,5.605-5.605
                            s5.605,2.526,5.605,5.605v4.5h2.131c1.422,0,2.605-1.185,2.605-2.605v-8.21c0-4.658-3.158-8.685-7.421-9.869
                            c1.185-0.71,2.053-1.816,2.447-3.158l0,0h6.079c0.869,0,1.579-0.71,1.579-1.579C30.947,4.658,30.236,3.947,29.369,3.947z
                            M17.921,8.763c-1.737,0-3.158-1.421-3.158-3.158c0-1.737,1.421-3.158,3.158-3.158c1.737,0,3.158,1.421,3.158,3.158
                            C21.158,7.342,19.736,8.763,17.921,8.763z"/>
                        <path d="M17.921,23.289c-1.737,0-3.158,1.422-3.158,3.158v6.395c0,1.737,1.421,3.158,3.158,3.158
                            c1.737,0,3.158-1.421,3.158-3.158v-6.395C21.079,24.711,19.658,23.289,17.921,23.289z"/>
                    </g>
                </g>
            </svg>`, iconExtend: `
            <svg width="48" x="{xCar}" y="30" height="48" viewBox="0 0 48 48" fill="{mapIconColor}">
            <g transform="rotate({iconRotate} 24 24)">
                <path d="M30.645,31.373c0,0-0.287-2.146-1.001-3.145c-0.718-1.006-0.864-1.007-1.154-1.579
                    c-0.282-0.572-1.145-2.575-1.423-3.298c-0.288-0.716-1.011-2.001-2.728-2.002c-1.72-0.001-2.437,1.288-2.724,2.006
                    c-0.282,0.716-1.149,2.723-1.43,3.295c-0.295,0.572-0.43,0.575-1.147,1.58c-0.722,1.003-1.004,3.15-1.004,3.15
                    s-0.286,2.151-0.145,3.867c0.216,2.575,1.048,5.285,2.005,6.884c0.864,1.431,2.44,2.722,2.723,2.865
                    c0.286,0.144,0.721,0.856,0.721,0.856l1.001,0.002l1.004-0.001c0,0,0.433-0.719,0.716-0.854c0.284-0.15,1.861-1.436,2.727-2.869
                    c0.955-1.596,1.789-4.312,2.006-6.882C30.93,33.528,30.645,31.373,30.645,31.373z"/>
                <path d="M26.403,41.848c-0.323,0.158-1.842,0.261-2.102,0.283c-0.263-0.024-1.781-0.122-2.113-0.287
                    c-0.286-0.146-0.669,0.455-0.144,0.719c0.522,0.262,1.997,0.639,2.257,0.595c0.262,0.044,1.73-0.335,2.251-0.596
                    C27.078,42.296,26.691,41.696,26.403,41.848z"/>
                <g>
                    <path d="M19.462,35.818c0,0-0.425,0.575-0.427,0.861c-0.002,0.293,0.43,1.002,0.428,1.292
                        c0.001,0.285,0.145,1.436,0.291,1.723c0.139,0.286,0.861,1.003,1.147,0.857C20.754,39.98,19.754,39.263,19.462,35.818z"/>
                    <path d="M29.064,35.823c0,0,0.439,0.572,0.436,0.857c0,0.292-0.426,1.004-0.439,1.291
                        c0.008,0.286-0.139,1.438-0.277,1.726c-0.143,0.284-0.865,1.001-1.143,0.854C27.781,39.979,28.779,39.268,29.064,35.823z"/>
                </g>
                <polygon stroke="#FFFFFF" stroke-width="0.0717" stroke-miterlimit="10" points="25.057,15.474 23.478,15.472
                    23.479,14.762 25.055,14.758 	"/>
                <path  d="M21.614,13.609c0,0,0.147-1.725,1.153-1.862c0.992-0.141,1.573-0.145,1.573-0.145l-0.004,1.004
                    C24.336,12.606,22.045,12.754,21.614,13.609z"/>
                <path  stroke="#FFFFFF" stroke-width="0.1433" stroke-miterlimit="10" d="M23.19,7.589c0,0-1.147-1.575,1.153-1.572
                    c2.146-0.002,0.998,1.574,0.998,1.574"/>
                <polygon  points="26.062,9.307 22.477,9.307 22.478,9.025 26.054,9.027 	"/>
                <path d="M12.733,13.178c0,0,0.139,0.576,0.424,0.576c0.292,0.003,1.004-0.287,1.004-0.287l-0.005-0.287
                    L12.733,13.178z"/>
                <line  x1="13.909" y1="13.321" x2="18.203" y2="13.18"/>
                <path d="M35.987,13.179c0,0-0.146,0.577-0.429,0.573c-0.291,0-1.004-0.286-1.004-0.286l0.002-0.285L35.987,13.179z"
                    />
                <g>
                    <polygon  stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" points="13.591,16.044 13.875,16.907
                        17.028,15.755 16.601,14.897 		"/>
                    <polygon stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" points="35.129,16.046 34.842,16.91
                        31.689,15.764 32.116,14.897 		"/>
                </g>
                <g>
                    <polygon stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" points="14.157,13.18 18.073,13.037
                        17.785,13.321 14.059,13.324 		"/>
                    <polygon  stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" points="34.446,13.184 30.398,13.031
                        30.699,13.324 34.557,13.32 		"/>
                </g>
                <path  d="M20.369,25.931c0,0-1.004,0.436-0.863,1.295C19.796,26.797,20.369,25.931,20.369,25.931z"/>
                <path  d="M28.244,25.936c0,0,1.004,0.428,0.861,1.288C28.819,26.79,28.244,25.936,28.244,25.936z"/>
                <path  d="M20.658,24.783"/>
                <path  d="M20.655,25.071c0,0,0.283-0.857-1.722-0.28c0.861-1.294,2.72-4.303,2.72-4.303"/>
                <polygon  stroke="#F2F2F2" stroke-width="0.0358" stroke-miterlimit="10" points="17.355,15.041 18.216,14.756
                    18.219,15.044 17.5,15.329 	"/>
                <polygon  points="17.494,15.467 18.362,15.185 18.359,15.473 17.642,15.755 	"/>
                <polygon  stroke="#F2F2F2" stroke-width="0.0358" stroke-miterlimit="10" points="17.494,15.467 18.362,15.185
                    18.359,15.473 17.642,15.755 	"/>
                <polygon  stroke="#F2F2F2" stroke-width="0.0358" stroke-miterlimit="10" points="30.113,15.045 30.828,15.329
                    30.83,15.76 29.967,15.474 	"/>
                <g>
                    <g>
                        <path  d="M31.118,13.613c-0.288-0.004-0.575-0.284-0.718-0.436c-0.147-0.141-0.289-0.141-0.145-0.28
                            c0.141-0.147,0.859-0.29,0.859-0.29s-3.58-1.719-3.727-1.864c-0.139-0.142-0.286-0.859-0.284-0.998
                            c-0.003-0.151,0.001-0.867-0.575-1.298c-0.514-0.383-1.956-0.307-2.241-0.293c-0.291-0.01-1.726-0.092-2.242,0.298
                            c-0.575,0.43-0.567,1.149-0.571,1.287c0.003,0.139-0.151,0.862-0.287,1.001c-0.146,0.148-3.727,1.867-3.727,1.867
                            s0.717,0.143,0.858,0.285c0.139,0.146,0.005,0.143-0.141,0.289c-0.149,0.139-0.432,0.43-0.717,0.428
                            c-0.289-0.005-0.717-0.002-0.721,0.146c0.002,0.139,0.004,0.569,0.144,0.861c0.144,0.28,0.431,0.715,0.431,0.715
                            s0.149,0.571,0.429,0.569c0.29,0.006,0.857-0.428,1.149-0.424c0.28-0.008,1.434-0.29,1.866-0.146
                            c0.424,0.137,2.006,0.285,2.574,1.002c0.354-0.232,0.806-0.276,0.954-0.288c0.149,0.012,0.6,0.051,0.949,0.293
                            c0.576-0.72,2.151-0.864,2.582-1.007c0.43-0.148,1.575,0.141,1.866,0.143c0.282,0.001,0.856,0.43,1.146,0.432
                            c0.288-0.002,0.426-0.574,0.426-0.574s0.291-0.43,0.438-0.718c0.137-0.284,0.139-0.721,0.14-0.854
                            C31.834,13.607,31.404,13.609,31.118,13.613z"/>
                        <path  d="M30.111,10.459c0-0.72-0.285-1.007-0.285-1.007s-1.723-1.289-3.152-2.148
                            c-0.147-3.874-1.006-4.729-1.291-5.012c-0.23-0.226-0.806-0.28-1.042-0.291l0,0c0,0-0.025-0.005-0.056,0.002
                            c-0.031-0.006-0.047-0.004-0.047-0.004l-0.003,0.005c-0.231,0.008-0.81,0.056-1.044,0.284c-0.285,0.291-1.146,1.146-1.29,5.014
                            c-1.437,0.86-3.153,2.151-3.153,2.151s-0.282,0.29-0.287,1.005c0.003,0.712-0.144,1.574,0.287,1.431
                            c0.434-0.145,2.152-1.146,2.152-1.146s0.284-0.289,0.431-0.715c0.147-0.426,0.147-0.426,0.284-1.288
                            c0.435-0.575,1.148-0.72,1.288-0.714c0.122-0.008,1.025-0.008,1.331-0.005c0.065,0.003,0.106-0.004,0.106-0.004
                            c0.308,0,1.208,0.003,1.33,0.006c0.148-0.002,0.855,0.142,1.293,0.715c0.139,0.856,0.139,0.856,0.283,1.289
                            c0.143,0.431,0.426,0.72,0.426,0.72s1.722,1.001,2.148,1.144C30.252,12.036,30.112,11.174,30.111,10.459z"/>
                    </g>
                    <g>
                        <path  d="M12.732,13.895c0,0-0.432-1.287-1.863-1.001C9.436,13.181,8,14.613,8,14.613L12.732,13.895z"/>
                        <path  d="M35.982,13.899c0,0,0.43-1.293,1.869-1.009c1.432,0.287,2.863,1.719,2.863,1.719L35.982,13.899z"/>
                    </g>
                    <g>
                        <polygon  points="28.926,24.076 19.614,24.069 19.613,15.477 28.925,15.474 			"/>
                        <path  d="M29.352,32.533c-0.146,2.715-0.148,10.163-5.017,9.598c-4.871,0.562-4.865-6.876-5.016-9.603
                            c-0.138-2.729,1.241-6.901,2.296-9.173c0.974-2.08,2.616-2.011,2.721-2.006c0.104-0.005,1.746-0.073,2.715,2
                            C28.107,25.624,29.49,29.804,29.352,32.533z"/>
                        <path  d="M29.406,27.367c0,0,0.432,0.718,0.577,0.72c0.14,0,0.282-0.144,0.286-0.286
                            c-0.004-0.139-0.002-1.434-0.002-1.434s0.428,0.143,0.434-0.004c0.001-0.139,0.002-0.856-0.717-1.146
                            c-0.718-0.288-1.434-0.288-1.584-0.144c-0.139,0.146-0.135,0.288,0.148,0.431C28.836,25.65,29.264,27.228,29.406,27.367z"/>
                        <path  d="M19.075,27.366c0,0-0.429,0.721-0.574,0.72c-0.141-0.003-0.279-0.146-0.285-0.292
                            c0.003-0.143,0-1.427,0-1.427s-0.432,0.142-0.43,0.002c0-0.149-0.001-0.864,0.718-1.152c0.723-0.284,1.431-0.289,1.574-0.14
                            c0.142,0.141,0.146,0.283-0.141,0.427C19.65,25.649,19.217,27.226,19.075,27.366z"/>
                    </g>
                </g>
                <path  d="M27.963,25.08c0,0-0.289-0.864,1.721-0.292c-0.859-1.288-2.727-4.3-2.727-4.3"/>
                <g>
                    <path  d="M25.785,13.065c-0.703-0.115-1.221-0.148-1.443-0.154l0.003-0.006c0,0-0.022,0.006-0.059,0.007
                        c-0.037-0.007-0.051-0.004-0.051-0.004l-0.005,0.006c-0.218,0.002-0.743,0.029-1.441,0.146c-0.937,0.157-2.174,1.561-2.174,1.561
                        s1.239,0.309,1.551,0.46c0.309,0.155,0.625,0.784,0.939,0.938c0.239,0.124,0.871,0.147,1.128,0.153l0,0c0,0,0.018,0,0.051-0.003
                        c0.036,0.003,0.063,0.005,0.063,0.005l0,0c0.254-0.009,0.886-0.028,1.124-0.156c0.315-0.158,0.626-0.779,0.937-0.938
                        c0.311-0.147,1.558-0.459,1.558-0.459S26.717,13.223,25.785,13.065z"/>
                    <path  d="M24.168,11.027c-0.449-0.001-2.781,0.081-2.651,2.153c0.56-0.422,1.396-0.839,2.65-0.995
                        c1.243,0.152,2.084,0.578,2.649,0.998C26.945,11.106,24.611,11.028,24.168,11.027z"/>
                </g>
                <g>
                    <path stroke="#FFFFFF" stroke-width="0.0717" stroke-miterlimit="10" d="M30.256,12.897
                        c0,0-0.715,0.997,0.574,0.997"/>
                        <line  stroke="#FFFFFF" stroke-width="0.0717" stroke-miterlimit="10" x1="30.402" y1="13.894" x2="29.536" y2="15.47"/>
                </g>
                <g>
                    <path  stroke="#FFFFFF" stroke-width="0.0717" stroke-miterlimit="10" d="M18.36,12.895c0,0,0.714,1-0.572,0.998"/>
                        <line  stroke="#FFFFFF" stroke-width="0.0717" stroke-miterlimit="10" x1="18.215" y1="13.896" x2="19.079" y2="15.477"/>
                </g>
                <path  d="M24.949,15.186c0.002,0.237-0.188,0.432-0.431,0.431h-0.715c-0.231,0.002-0.425-0.196-0.428-0.432l0,0
                    c0-0.237,0.19-0.43,0.431-0.429l0.714,0.002C24.76,14.756,24.955,14.951,24.949,15.186L24.949,15.186z"/>

                    <line  stroke="#FFFFFF" stroke-width="0.0358" stroke-miterlimit="10" x1="21.51" y1="15.617" x2="21.514" y2="20.483"/>

                    <line  stroke="#FFFFFF" stroke-width="0.0358" stroke-miterlimit="10" x1="26.814" y1="15.616" x2="26.818" y2="20.486"/>
                <g>
                    <path fill="{bgCarSub}"  d="M25.318,13.358c-0.406-0.142-1.083-0.138-1.083-0.138s-0.672,0.001-1.077,0.131
                        c-0.408,0.135-1.357,0.68-1.355,0.951c0,0.273,0.54,0.272,0.806,0.271c0.275,0.001-0.001,0.134,0.542-0.139
                        c0.549-0.263,1.084-0.132,1.084-0.132s0.545-0.138,1.083,0.136c0.539,0.269,0.27,0.14,0.543,0.137
                        c0.268-0.002,0.808-0.003,0.811-0.272C26.674,14.031,25.725,13.491,25.318,13.358z"/>
                    <path fill="{bgCarSub}"  stroke="#F2F2F2" stroke-width="0.0717" stroke-miterlimit="10" d="M25.951,21.777
                        c-0.135-0.72,0.092-4.23-0.711-5.299c-0.131-0.173-0.676-0.341-0.9-0.406l0-0.03c0,0-0.028,0.007-0.062,0.013
                        c-0.027-0.003-0.042-0.014-0.042-0.014v0.029c-0.245,0.047-0.842,0.197-0.899,0.41c-0.803,1.066-0.574,4.581-0.723,5.301
                        c0.472-0.476,1.327-0.456,1.624-0.442l-0.003,0.012c0,0,0.02-0.005,0.058-0.009c0.026,0.002,0.045,0.008,0.045,0.008v-0.008
                        C24.631,21.319,25.486,21.309,25.951,21.777z"/>
                </g>
                <path fill="{bgCarSub}" d="M28.49,32.098c0,0,0.146,2.004-4.151,2.148c-4.302-0.143-4.154-2.152-4.154-2.152
                    c-0.433-0.859,1.43-3.438,2.288-5.01c0.861-1.58,0.575-2.723,0.575-2.723c0.283-1.724-0.28-2.151-0.283-2.581
                    c0-0.427,1.573-0.431,1.573-0.431s1.578-0.001,1.582,0.429c-0.004,0.433-0.576,0.855-0.287,2.577c0,0-0.293,1.15,0.568,2.729
                    C27.065,28.657,28.927,31.237,28.49,32.098z"/>
                <path fill="{bgCarSub}" d="M24.339,43.279c0,0-1.865-0.144-2.293-0.572c0,0,0.576,2.432,1.007,2.865
                    c0.433,0.427,0.286,0.281,1.289,0.429c1.001-0.146,0.853-0.004,1.287-0.43c0.429-0.433,1.003-2.864,1.003-2.864
                    C26.203,43.137,24.339,43.279,24.339,43.279z"/>
                <g>
                    <path  d="M20.677,8.905c-0.012,0.005-0.028,0.009-0.042,0.007c0.005-0.01,0.003-0.022,0.004-0.032
                        c-0.007,0.017-0.01,0.025-0.023,0.037c-1.237,0.279-1.294,1.8-1.294,1.8c0.005,0.007,0.021,0.007,0.02,0.005
                        c0.008,0.009,0.008,0.013,0.016,0.019c0,0,1.417-0.545,1.284-1.802C20.657,8.926,20.669,8.921,20.677,8.905z"/>
                    <path  d="M27.93,8.938c-0.131,1.261,1.289,1.804,1.289,1.804c0.009,0,0.01-0.018,0.007-0.02
                        c0.012-0.002,0.021-0.009,0.026-0.005c0,0-0.057-1.52-1.303-1.794c-0.006-0.018-0.006-0.027-0.012-0.042
                        c-0.004,0.013-0.001,0.017-0.002,0.037C27.92,8.912,27.912,8.905,27.9,8.907C27.91,8.915,27.92,8.927,27.93,8.938z"/>
                </g>
            </g>
            </svg>`, key: "5", name: "motorbike"
      }),
      // motorcycles
      new IconType({
        icon: `<svg x="3" y="2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24"
                viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                    <g fill="#495156">
                        <path d="M29.908,16.232c0.428-0.278,0.701-0.453,0.701-0.453l-0.443-0.395c0,0,1.283-0.295,0.986-0.938
                            s-3.404-4.491-3.404-4.491s0.551-1.185-0.588-2.023l-0.547,1.185c0,0-1.395-0.963-1.951-0.777c-0.555,0.186-1.387,0.277-1.646,0.685
                            l-0.26,0.241c0,0-0.072,0.426,0.352,1.055c0,0,4.275,1.314,4.48,2.276c0,0,0.295,0.611-0.982,0.759l-0.258,0.334l-0.686,0.036
                            l-1.238-2.831c0,0-1.186,0.314-1.797,0.795l0.094,0.48c0,0-4.979-3.387-9.66,1.629c0,0-3.461,1-5.886-2.072c0,0-0.037-1-5.885-0.814
                            l0.407,0.426c0,0-0.407,0.037-0.574,0.296c-0.166,0.259,0.094,0.536,1.426,0.536c0,0,3.018,3.442,5.33,3.239L11.173,17l1.462,2.5
                            c0,0,0.003,0.213,0.018,0.541c-0.339,0.072-0.719,0.154-1.123,0.238c0,0-1.805,0.352-1.793,0.383
                            c-2.178,0.463-4.34,0.924-4.34,0.924l0.279,1.537l4.264-0.189c0.642-0.033,1.848-0.082,1.848-0.082s-0.019,0.799-0.498,1.764
                            l1.406,1.084c0,0,10.34,0.074,10.906-0.246c0,0-1.283-3.504,0.346-4.986c0.172-0.156,0.387-0.334,0.625-0.521
                            C26.284,16.104,29.963,16.23,29.908,16.232z M11.607,23.75c0.081-0.291,0.143-0.592,0.18-0.898l1.236-0.055
                            c0.121,0.412,0.279,0.738,0.486,0.879L11.607,23.75z"/>
                        <path d="M30.082,16.225c-3.271,0-5.922,2.652-5.922,5.921c0,3.271,2.651,5.922,5.922,5.922
                            c3.166,0,5.744-2.486,5.906-5.611v-0.619C35.826,18.713,33.248,16.225,30.082,16.225z M30.082,26.254
                            c-2.265,0-4.106-1.842-4.106-4.105c0-2.266,1.842-4.108,4.106-4.108c2.266,0,4.107,1.842,4.107,4.108
                            C34.189,24.412,32.348,26.254,30.082,26.254z"/>
                        <path d="M5.934,16.225c-3.271,0-5.922,2.652-5.922,5.921c0,3.271,2.651,5.922,5.922,5.922
                            c3.166,0,5.744-2.486,5.906-5.611v-0.619C11.678,18.713,9.1,16.225,5.934,16.225z M5.934,26.254c-2.265,0-4.106-1.842-4.106-4.105
                            c0-2.266,1.842-4.108,4.106-4.108c2.266,0,4.107,1.842,4.107,4.108C10.041,24.412,8.199,26.254,5.934,26.254z"/>
                    </g>
                </g>
            </svg>`,
        iconExtend: `
            <svg width="48" x="{xCar}" y="30" height="48" viewBox="0 0 48 48" fill="{mapIconColor}">
                <g transform="rotate({iconRotate} 24 24)">
                    <path d="M30.645,31.373c0,0-0.287-2.146-1.001-3.145c-0.718-1.006-0.864-1.007-1.154-1.579
                        c-0.282-0.572-1.145-2.575-1.423-3.298c-0.288-0.716-1.011-2.001-2.728-2.002c-1.72-0.001-2.437,1.288-2.724,2.006
                        c-0.282,0.716-1.149,2.723-1.43,3.295c-0.295,0.572-0.43,0.575-1.147,1.58c-0.722,1.003-1.004,3.15-1.004,3.15
                        s-0.286,2.151-0.145,3.867c0.216,2.575,1.048,5.285,2.005,6.884c0.864,1.431,2.44,2.722,2.723,2.865
                        c0.286,0.144,0.721,0.856,0.721,0.856l1.001,0.002l1.004-0.001c0,0,0.433-0.719,0.716-0.854c0.284-0.15,1.861-1.436,2.727-2.869
                        c0.955-1.596,1.789-4.312,2.006-6.882C30.93,33.528,30.645,31.373,30.645,31.373z"/>
                    <path d="M26.403,41.848c-0.323,0.158-1.842,0.261-2.102,0.283c-0.263-0.024-1.781-0.122-2.113-0.287
                        c-0.286-0.146-0.669,0.455-0.144,0.719c0.522,0.262,1.997,0.639,2.257,0.595c0.262,0.044,1.73-0.335,2.251-0.596
                        C27.078,42.296,26.691,41.696,26.403,41.848z"/>
                    <g>
                        <path d="M19.462,35.818c0,0-0.425,0.575-0.427,0.861c-0.002,0.293,0.43,1.002,0.428,1.292
                            c0.001,0.285,0.145,1.436,0.291,1.723c0.139,0.286,0.861,1.003,1.147,0.857C20.754,39.98,19.754,39.263,19.462,35.818z"/>
                        <path d="M29.064,35.823c0,0,0.439,0.572,0.436,0.857c0,0.292-0.426,1.004-0.439,1.291
                            c0.008,0.286-0.139,1.438-0.277,1.726c-0.143,0.284-0.865,1.001-1.143,0.854C27.781,39.979,28.779,39.268,29.064,35.823z"/>
                    </g>
                    <polygon stroke="#FFFFFF" stroke-width="0.0717" stroke-miterlimit="10" points="25.057,15.474 23.478,15.472
                        23.479,14.762 25.055,14.758 	"/>
                    <path  d="M21.614,13.609c0,0,0.147-1.725,1.153-1.862c0.992-0.141,1.573-0.145,1.573-0.145l-0.004,1.004
                        C24.336,12.606,22.045,12.754,21.614,13.609z"/>
                    <path  stroke="#FFFFFF" stroke-width="0.1433" stroke-miterlimit="10" d="M23.19,7.589c0,0-1.147-1.575,1.153-1.572
                        c2.146-0.002,0.998,1.574,0.998,1.574"/>
                    <polygon  points="26.062,9.307 22.477,9.307 22.478,9.025 26.054,9.027 	"/>
                    <path d="M12.733,13.178c0,0,0.139,0.576,0.424,0.576c0.292,0.003,1.004-0.287,1.004-0.287l-0.005-0.287
                        L12.733,13.178z"/>
                    <line  x1="13.909" y1="13.321" x2="18.203" y2="13.18"/>
                    <path d="M35.987,13.179c0,0-0.146,0.577-0.429,0.573c-0.291,0-1.004-0.286-1.004-0.286l0.002-0.285L35.987,13.179z"
                        />
                    <g>
                        <polygon  stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" points="13.591,16.044 13.875,16.907
                            17.028,15.755 16.601,14.897 		"/>
                        <polygon stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" points="35.129,16.046 34.842,16.91
                            31.689,15.764 32.116,14.897 		"/>
                    </g>
                    <g>
                        <polygon stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" points="14.157,13.18 18.073,13.037
                            17.785,13.321 14.059,13.324 		"/>
                        <polygon  stroke="#FFFFFF" stroke-width="0.25" stroke-miterlimit="10" points="34.446,13.184 30.398,13.031
                            30.699,13.324 34.557,13.32 		"/>
                    </g>
                    <path  d="M20.369,25.931c0,0-1.004,0.436-0.863,1.295C19.796,26.797,20.369,25.931,20.369,25.931z"/>
                    <path  d="M28.244,25.936c0,0,1.004,0.428,0.861,1.288C28.819,26.79,28.244,25.936,28.244,25.936z"/>
                    <path  d="M20.658,24.783"/>
                    <path  d="M20.655,25.071c0,0,0.283-0.857-1.722-0.28c0.861-1.294,2.72-4.303,2.72-4.303"/>
                    <polygon  stroke="#F2F2F2" stroke-width="0.0358" stroke-miterlimit="10" points="17.355,15.041 18.216,14.756
                        18.219,15.044 17.5,15.329 	"/>
                    <polygon  points="17.494,15.467 18.362,15.185 18.359,15.473 17.642,15.755 	"/>
                    <polygon  stroke="#F2F2F2" stroke-width="0.0358" stroke-miterlimit="10" points="17.494,15.467 18.362,15.185
                        18.359,15.473 17.642,15.755 	"/>
                    <polygon  stroke="#F2F2F2" stroke-width="0.0358" stroke-miterlimit="10" points="30.113,15.045 30.828,15.329
                        30.83,15.76 29.967,15.474 	"/>
                    <g>
                        <g>
                            <path  d="M31.118,13.613c-0.288-0.004-0.575-0.284-0.718-0.436c-0.147-0.141-0.289-0.141-0.145-0.28
                                c0.141-0.147,0.859-0.29,0.859-0.29s-3.58-1.719-3.727-1.864c-0.139-0.142-0.286-0.859-0.284-0.998
                                c-0.003-0.151,0.001-0.867-0.575-1.298c-0.514-0.383-1.956-0.307-2.241-0.293c-0.291-0.01-1.726-0.092-2.242,0.298
                                c-0.575,0.43-0.567,1.149-0.571,1.287c0.003,0.139-0.151,0.862-0.287,1.001c-0.146,0.148-3.727,1.867-3.727,1.867
                                s0.717,0.143,0.858,0.285c0.139,0.146,0.005,0.143-0.141,0.289c-0.149,0.139-0.432,0.43-0.717,0.428
                                c-0.289-0.005-0.717-0.002-0.721,0.146c0.002,0.139,0.004,0.569,0.144,0.861c0.144,0.28,0.431,0.715,0.431,0.715
                                s0.149,0.571,0.429,0.569c0.29,0.006,0.857-0.428,1.149-0.424c0.28-0.008,1.434-0.29,1.866-0.146
                                c0.424,0.137,2.006,0.285,2.574,1.002c0.354-0.232,0.806-0.276,0.954-0.288c0.149,0.012,0.6,0.051,0.949,0.293
                                c0.576-0.72,2.151-0.864,2.582-1.007c0.43-0.148,1.575,0.141,1.866,0.143c0.282,0.001,0.856,0.43,1.146,0.432
                                c0.288-0.002,0.426-0.574,0.426-0.574s0.291-0.43,0.438-0.718c0.137-0.284,0.139-0.721,0.14-0.854
                                C31.834,13.607,31.404,13.609,31.118,13.613z"/>
                            <path  d="M30.111,10.459c0-0.72-0.285-1.007-0.285-1.007s-1.723-1.289-3.152-2.148
                                c-0.147-3.874-1.006-4.729-1.291-5.012c-0.23-0.226-0.806-0.28-1.042-0.291l0,0c0,0-0.025-0.005-0.056,0.002
                                c-0.031-0.006-0.047-0.004-0.047-0.004l-0.003,0.005c-0.231,0.008-0.81,0.056-1.044,0.284c-0.285,0.291-1.146,1.146-1.29,5.014
                                c-1.437,0.86-3.153,2.151-3.153,2.151s-0.282,0.29-0.287,1.005c0.003,0.712-0.144,1.574,0.287,1.431
                                c0.434-0.145,2.152-1.146,2.152-1.146s0.284-0.289,0.431-0.715c0.147-0.426,0.147-0.426,0.284-1.288
                                c0.435-0.575,1.148-0.72,1.288-0.714c0.122-0.008,1.025-0.008,1.331-0.005c0.065,0.003,0.106-0.004,0.106-0.004
                                c0.308,0,1.208,0.003,1.33,0.006c0.148-0.002,0.855,0.142,1.293,0.715c0.139,0.856,0.139,0.856,0.283,1.289
                                c0.143,0.431,0.426,0.72,0.426,0.72s1.722,1.001,2.148,1.144C30.252,12.036,30.112,11.174,30.111,10.459z"/>
                        </g>
                        <g>
                            <path  d="M12.732,13.895c0,0-0.432-1.287-1.863-1.001C9.436,13.181,8,14.613,8,14.613L12.732,13.895z"/>
                            <path  d="M35.982,13.899c0,0,0.43-1.293,1.869-1.009c1.432,0.287,2.863,1.719,2.863,1.719L35.982,13.899z"/>
                        </g>
                        <g>
                            <polygon  points="28.926,24.076 19.614,24.069 19.613,15.477 28.925,15.474 			"/>
                            <path  d="M29.352,32.533c-0.146,2.715-0.148,10.163-5.017,9.598c-4.871,0.562-4.865-6.876-5.016-9.603
                                c-0.138-2.729,1.241-6.901,2.296-9.173c0.974-2.08,2.616-2.011,2.721-2.006c0.104-0.005,1.746-0.073,2.715,2
                                C28.107,25.624,29.49,29.804,29.352,32.533z"/>
                            <path  d="M29.406,27.367c0,0,0.432,0.718,0.577,0.72c0.14,0,0.282-0.144,0.286-0.286
                                c-0.004-0.139-0.002-1.434-0.002-1.434s0.428,0.143,0.434-0.004c0.001-0.139,0.002-0.856-0.717-1.146
                                c-0.718-0.288-1.434-0.288-1.584-0.144c-0.139,0.146-0.135,0.288,0.148,0.431C28.836,25.65,29.264,27.228,29.406,27.367z"/>
                            <path  d="M19.075,27.366c0,0-0.429,0.721-0.574,0.72c-0.141-0.003-0.279-0.146-0.285-0.292
                                c0.003-0.143,0-1.427,0-1.427s-0.432,0.142-0.43,0.002c0-0.149-0.001-0.864,0.718-1.152c0.723-0.284,1.431-0.289,1.574-0.14
                                c0.142,0.141,0.146,0.283-0.141,0.427C19.65,25.649,19.217,27.226,19.075,27.366z"/>
                        </g>
                    </g>
                    <path  d="M27.963,25.08c0,0-0.289-0.864,1.721-0.292c-0.859-1.288-2.727-4.3-2.727-4.3"/>
                    <g>
                        <path  d="M25.785,13.065c-0.703-0.115-1.221-0.148-1.443-0.154l0.003-0.006c0,0-0.022,0.006-0.059,0.007
                            c-0.037-0.007-0.051-0.004-0.051-0.004l-0.005,0.006c-0.218,0.002-0.743,0.029-1.441,0.146c-0.937,0.157-2.174,1.561-2.174,1.561
                            s1.239,0.309,1.551,0.46c0.309,0.155,0.625,0.784,0.939,0.938c0.239,0.124,0.871,0.147,1.128,0.153l0,0c0,0,0.018,0,0.051-0.003
                            c0.036,0.003,0.063,0.005,0.063,0.005l0,0c0.254-0.009,0.886-0.028,1.124-0.156c0.315-0.158,0.626-0.779,0.937-0.938
                            c0.311-0.147,1.558-0.459,1.558-0.459S26.717,13.223,25.785,13.065z"/>
                        <path  d="M24.168,11.027c-0.449-0.001-2.781,0.081-2.651,2.153c0.56-0.422,1.396-0.839,2.65-0.995
                            c1.243,0.152,2.084,0.578,2.649,0.998C26.945,11.106,24.611,11.028,24.168,11.027z"/>
                    </g>
                    <g>
                        <path stroke="#FFFFFF" stroke-width="0.0717" stroke-miterlimit="10" d="M30.256,12.897
                            c0,0-0.715,0.997,0.574,0.997"/>

                            <line  stroke="#FFFFFF" stroke-width="0.0717" stroke-miterlimit="10" x1="30.402" y1="13.894" x2="29.536" y2="15.47"/>
                    </g>
                    <g>
                        <path  stroke="#FFFFFF" stroke-width="0.0717" stroke-miterlimit="10" d="M18.36,12.895c0,0,0.714,1-0.572,0.998"/>

                            <line  stroke="#FFFFFF" stroke-width="0.0717" stroke-miterlimit="10" x1="18.215" y1="13.896" x2="19.079" y2="15.477"/>
                    </g>
                    <path  d="M24.949,15.186c0.002,0.237-0.188,0.432-0.431,0.431h-0.715c-0.231,0.002-0.425-0.196-0.428-0.432l0,0
                        c0-0.237,0.19-0.43,0.431-0.429l0.714,0.002C24.76,14.756,24.955,14.951,24.949,15.186L24.949,15.186z"/>

                        <line  stroke="#FFFFFF" stroke-width="0.0358" stroke-miterlimit="10" x1="21.51" y1="15.617" x2="21.514" y2="20.483"/>

                        <line  stroke="#FFFFFF" stroke-width="0.0358" stroke-miterlimit="10" x1="26.814" y1="15.616" x2="26.818" y2="20.486"/>
                    <g>
                        <path fill="{bgCarSub}"  d="M25.318,13.358c-0.406-0.142-1.083-0.138-1.083-0.138s-0.672,0.001-1.077,0.131
                            c-0.408,0.135-1.357,0.68-1.355,0.951c0,0.273,0.54,0.272,0.806,0.271c0.275,0.001-0.001,0.134,0.542-0.139
                            c0.549-0.263,1.084-0.132,1.084-0.132s0.545-0.138,1.083,0.136c0.539,0.269,0.27,0.14,0.543,0.137
                            c0.268-0.002,0.808-0.003,0.811-0.272C26.674,14.031,25.725,13.491,25.318,13.358z"/>
                        <path fill="{bgCarSub}"  stroke="#F2F2F2" stroke-width="0.0717" stroke-miterlimit="10" d="M25.951,21.777
                            c-0.135-0.72,0.092-4.23-0.711-5.299c-0.131-0.173-0.676-0.341-0.9-0.406l0-0.03c0,0-0.028,0.007-0.062,0.013
                            c-0.027-0.003-0.042-0.014-0.042-0.014v0.029c-0.245,0.047-0.842,0.197-0.899,0.41c-0.803,1.066-0.574,4.581-0.723,5.301
                            c0.472-0.476,1.327-0.456,1.624-0.442l-0.003,0.012c0,0,0.02-0.005,0.058-0.009c0.026,0.002,0.045,0.008,0.045,0.008v-0.008
                            C24.631,21.319,25.486,21.309,25.951,21.777z"/>
                    </g>
                    <path fill="{bgCarSub}" d="M28.49,32.098c0,0,0.146,2.004-4.151,2.148c-4.302-0.143-4.154-2.152-4.154-2.152
                        c-0.433-0.859,1.43-3.438,2.288-5.01c0.861-1.58,0.575-2.723,0.575-2.723c0.283-1.724-0.28-2.151-0.283-2.581
                        c0-0.427,1.573-0.431,1.573-0.431s1.578-0.001,1.582,0.429c-0.004,0.433-0.576,0.855-0.287,2.577c0,0-0.293,1.15,0.568,2.729
                        C27.065,28.657,28.927,31.237,28.49,32.098z"/>
                    <path fill="{bgCarSub}" d="M24.339,43.279c0,0-1.865-0.144-2.293-0.572c0,0,0.576,2.432,1.007,2.865
                        c0.433,0.427,0.286,0.281,1.289,0.429c1.001-0.146,0.853-0.004,1.287-0.43c0.429-0.433,1.003-2.864,1.003-2.864
                        C26.203,43.137,24.339,43.279,24.339,43.279z"/>
                    <g>
                        <path  d="M20.677,8.905c-0.012,0.005-0.028,0.009-0.042,0.007c0.005-0.01,0.003-0.022,0.004-0.032
                            c-0.007,0.017-0.01,0.025-0.023,0.037c-1.237,0.279-1.294,1.8-1.294,1.8c0.005,0.007,0.021,0.007,0.02,0.005
                            c0.008,0.009,0.008,0.013,0.016,0.019c0,0,1.417-0.545,1.284-1.802C20.657,8.926,20.669,8.921,20.677,8.905z"/>
                        <path  d="M27.93,8.938c-0.131,1.261,1.289,1.804,1.289,1.804c0.009,0,0.01-0.018,0.007-0.02
                            c0.012-0.002,0.021-0.009,0.026-0.005c0,0-0.057-1.52-1.303-1.794c-0.006-0.018-0.006-0.027-0.012-0.042
                            c-0.004,0.013-0.001,0.017-0.002,0.037C27.92,8.912,27.912,8.905,27.9,8.907C27.91,8.915,27.92,8.927,27.93,8.938z"/>
                    </g>
                </g>
                </svg>`, key: "6", name: "motorcycles"
      }),
      // horse
      new IconType({
        icon: `<svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="24px" height="24px"><path d="M 39.3125 0 C 39.230469 0 39.140625
                0.0117188 39.0625 0.03125 C 38.9375 0.0585938 36.582031 0.6875 35.3125 1.9375 C 34.042969 3.1875 33.371094 4.839844 33.125 6.09375 C 20.824219 6.492188 9
                13.394531 9 28.09375 C 9 31.894531 10.113281 38 11.8125 40.5 C 11.8125 40.5 13.410156 43.757813 21.15625 48.09375 C 26.425781 49.851563 31.726563 50 33.46875
                50 C 33.78125 50 33.96875 50.003906 34.03125 50 C 34.453125 49.984375 34.808594 49.714844 34.9375 49.3125 C 34.976563 49.191406 35.90625 46.308594 35.90625
                43.1875 C 35.90625 39.941406 34.605469 37.867188 33.34375 35.84375 C 31.996094 33.6875 30.59375 31.453125 30.59375 27.5 C 30.59375 25.476563 31.324219
                23.96875 32.75 23 C 34.8125 26.863281 38.71875 27.804688 40.34375 28.03125 C 40.675781 29.003906 41.425781 30.648438 42.78125 31.1875 C 43.226563
                31.363281 43.878906 31.480469 44.65625 31.1875 C 44.960938 31.359375 45.320313 31.476563 45.6875 31.4375 C 46.75 31.421875 47.816406 30.675781 48.5625
                29.40625 C 48.632813 29.289063 48.71875 29.167969 48.8125 29.03125 C 49.628906 27.800781 51.300781 25.261719 47.59375 21.6875 C 45.820313 14.066406
                42.644531 10.304688 41.4375 8.875 L 41.28125 8.65625 C 40.601563 7.84375 39.882813 7.085938 39.46875 6.65625 L 40.28125 1.15625 C 40.332031 0.828125
                40.210938 0.503906 39.96875 0.28125 C 39.785156 0.113281 39.554688 0.00390625 39.3125 0 Z M 32.40625 0.1875 C 32.367188 0.1875 32.320313 0.210938 32.28125
                0.21875 C 31.515625 0.3125 30.972656 0.953125 30.4375 1.5625 C 30.207031 1.824219 29.785156 2.285156 29.5625 2.40625 C 29.351563 2.351563 28.925781 2.027344
                28.65625 1.84375 C 27.882813 1.3125 26.910156 0.652344 25.8125 0.90625 C 24.898438 1.089844 24.34375 1.976563 23.8125 2.8125 C 23.601563 3.148438 23.21875
                3.742188 23 3.90625 C 22.773438 3.839844 22.316406 3.546875 22.03125 3.375 C 21.175781 2.863281 20.273438 2.355469 19.375 2.65625 C 18.429688 2.96875 18.125
                4.011719 17.84375 4.9375 C 17.75 5.246094 17.59375 5.75 17.46875 5.96875 C 17.214844 5.933594 16.71875 5.699219 16.40625 5.5625 C 15.503906 5.171875 14.492188
                4.753906 13.5625 5.21875 C 12.589844 5.707031 12.625 6.84375 12.65625 7.78125 C 12.667969 8.058594 12.660156 8.5 12.625 8.71875 C 12.375 8.832031 11.960938
                8.910156 11.5625 8.96875 C 10.753906 9.089844 9.855469 9.222656 9.15625 9.84375 C 8.457031 10.445313 8.4375 11.375 8.4375 12.1875 C 8.4375 12.511719 8.4375
                13.085938 8.34375 13.21875 C 8.210938 13.328125 7.652344 13.425781 7.3125 13.46875 C 6.472656 13.574219 5.4375 13.699219 4.90625 14.5 C 4.273438 15.339844
                4.652344 16.402344 4.96875 17.25 C 5.09375 17.582031 5.332031 18.199219 5.3125 18.34375 C 5.191406 18.496094 4.582031 18.714844 4.21875 18.84375 C 3.28125
                19.179688 2.128906 19.578125 1.75 20.71875 C 1.527344 21.5 2.003906 22.273438 2.46875 23 C 2.59375 23.199219 2.78125 23.492188 2.875 23.6875 C 2.722656
                23.816406 2.390625 23.980469 2.1875 24.09375 C 1.515625 24.464844 0.601563 24.984375 0.40625 26.0625 C 0.296875 26.839844 0.867188 27.507813 1.40625 28.15625
                C 1.578125 28.363281 1.847656 28.703125 1.96875 28.90625 C 1.84375 29.03125 1.59375 29.199219 1.4375 29.3125 C 0.832031 29.761719 0 30.378906 0 31.40625 C 0
                32.105469 0.507813 32.71875 1.03125 33.375 C 1.222656 33.613281 1.519531 34.011719 1.59375 34.1875 C 1.609375 34.367188 1.464844 34.542969 1.15625 34.90625 C
                0.753906 35.378906 0.222656 36.023438 0.3125 37 C 0.425781 38.007813 1.394531 38.535156 2.1875 38.9375 C 2.425781 39.058594 2.828125 39.253906 2.96875 39.375
                C 3.027344 39.519531 3.132813 39.644531 3.25 39.75 C 3.34375 39.835938 5.398438 41.617188 10.125 41.59375 C 7.996094 38.417969 7 31.664063 7 28.09375 C 7
                12.636719 19.015625 5.027344 31.78125 4.15625 C 32.113281 3.335938 32.667969 2.066406 33.78125 0.75 C 32.945313 0.214844 32.566406 0.1875 32.40625 0.1875 Z
                M 42.1875 1.90625 L 41.59375 6 C 41.761719 6.183594 41.945313 6.390625 42.125 6.59375 C 42.4375 5.621094 42.914063 4.558594 43.71875 3.6875 C 43.960938 3.425781
                44.070313 3.058594 43.96875 2.71875 C 43.867188 2.378906 43.566406 2.113281 43.21875 2.03125 C 42.898438 1.953125 42.578125 1.90625 42.25 1.90625 C 42.230469
                1.90625 42.207031 1.90625 42.1875 1.90625 Z M 39.5 15 C 40.328125 15 41 15.671875 41 16.5 C 41 17.328125 40.328125 18 39.5 18 C 38.671875 18 38 17.328125 38
                16.5 C 38 15.671875 38.671875 15 39.5 15 Z"/></svg>`,
        iconExtend: 'a',
        key: '7', name: 'horse'
      }),
      // boat
      new IconType({
        icon: `<svg id="Capa_1" fill="#fff" enable-background="new 0 0 512 512" height="22" viewBox="0 0 512 512" width="22" xmlns="http://www.w3.org/2000/svg"><g><path d="m32.007
                300.538h179v-233.467l-190.081 208.358c-8.759 9.6-1.94 25.109 11.081 25.109z"/><path d="m301.007 231h179c6.395 0 12.086-4.054 14.176-10.097 2.091-6.044.119-12.747-4.908-16.698l-188.268-147.925z
               "/><path d="m480.007 337h-209c0-39.557 0-280.977 0-322 0-8.284-6.716-15-15-15s-15 6.716-15 15v322h-209c-8.284 0-15 6.716-15 15v64c0
                49.333 53.649 96 126.932 96h224.137c32.812 0 64.072-9.683 88.023-27.264 24.727-18.15 38.908-43.204 38.908-68.736v-64c0-8.284-6.716-15-15-15zm-320 102h-32c-8.284
                0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm110.93-13.521c-.821 7.922-7.705 14.161-16.41 13.45-8.488-.882-14.261-8.428-13.45-16.41.912
                -8.616 8.81-14.925 17.86-13.229 7.344 1.523 12.664 8.077 12 16.189zm113.07 13.521h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15
                15z"/></g></svg>`,
        iconExtend: `<svg id="Layer_2"  x="{xCar}" y="30" height="48" viewBox="0 0 128 128" width="48" xmlns="http://www.w3.org/2000/svg" data-name="Layer 2"><path d="m82.474 7.367a26.847 26.847 0 0 0
                -36.948 0 80.317 80.317 0 0 0 -24.943 58.033v55.85a6.758 6.758 0 0 0 6.75 6.75h73.334a6.758 6.758 0 0 0 6.75-6.75v-55.85a80.317 80.317 0 0 0 -24.943-58.033zm-58.391 66.55h4.334v31h-4.334zm76.584
                 50.583h-73.334a3.254 3.254 0 0 1 -3.25-3.25v-12.833h6.084a1.75 1.75 0 0 0 1.75-1.75v-34.5a1.75 1.75 0 0 0 -1.75-1.75h-6.084v-5.017a76.8 76.8 0 0 1 23.852-55.494 23.347 23.347 0 0 1 32.13 0
                 76.8 76.8 0 0 1 23.852 55.494v5.02h-6.084a1.75 1.75 0 0 0 -1.75 1.75v34.5a1.75 1.75 0 0 0 1.75 1.75h6.084v12.83a3.254 3.254 0 0 1 -3.25 3.25zm3.25-19.583h-4.334v-31h4.334z"/><path d="m92.036
                 58.479a79.886 79.886 0 0 0 -1.566-8.739 68.343 68.343 0 0 0 -8.555-19.7 64.579 64.579 0 0 0 -9.763-11.79 11.848 11.848 0 0 0 -16.3 0 64.6 64.6 0 0 0 -9.762 11.791 68.381 68.381 0 0 0 -8.556
                 19.7 55.413 55.413 0 0 0 -1.566 8.741 64.229 64.229 0 0 0 -.381 6.916v45.852a1.75 1.75 0 0 0 1.75 1.75h53.33a1.75 1.75 0 0 0 1.75-1.75v-45.85a64.229 64.229 0 0 0 -.381-6.916zm-3.119
                 36.771h-49.834v-5h49.834zm-44.864-54h39.894a61.77 61.77 0 0 1 2.538 7.167h-44.97a61.77 61.77 0 0 1 2.538-7.167zm38.247-3.5h-36.6c.861-1.709 1.8-3.381 2.815-5h30.974c1.011 1.619 1.954
                 3.291 2.811 5zm-42.574 18.928q.348-2.4.886-4.761h46.776q.561 2.48.917 5h-48.605c.006-.08.014-.16.026-.239zm48.966 3.739c.136 1.653.225 3.313.225 4.98v2.186h-49.834v-2.183c0-1.667.089-3.327.225-4.98zm.225
                  15.666h-49.834v-5h49.834zm-49.834 10.667v-7.167h49.834v7.167zm19.173-65.959a8.348 8.348 0 0 1 11.488 0 61 61 0 0 1 7.386 8.46h-26.26a61 61 0 0 1 7.386-8.46zm-19.173
                  88.709v-10.75h49.834v10.75z"/></svg>`,
        key: '8', name: 'boat'
      }),
      // boat
      new IconType({
        icon: `<svg id="Capa_1" fill="#fff" enable-background="new 0 0 512 512" height="22" viewBox="0 0 512 512" width="22" xmlns="http://www.w3.org/2000/svg"><g><path d="m32.007
                300.538h179v-233.467l-190.081 208.358c-8.759 9.6-1.94 25.109 11.081 25.109z"/><path d="m301.007 231h179c6.395 0 12.086-4.054 14.176-10.097 2.091-6.044.119-12.747-4.908-16.698l-188.268-147.925z
               "/><path d="m480.007 337h-209c0-39.557 0-280.977 0-322 0-8.284-6.716-15-15-15s-15 6.716-15 15v322h-209c-8.284 0-15 6.716-15 15v64c0
                49.333 53.649 96 126.932 96h224.137c32.812 0 64.072-9.683 88.023-27.264 24.727-18.15 38.908-43.204 38.908-68.736v-64c0-8.284-6.716-15-15-15zm-320 102h-32c-8.284
                0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm110.93-13.521c-.821 7.922-7.705 14.161-16.41 13.45-8.488-.882-14.261-8.428-13.45-16.41.912
                -8.616 8.81-14.925 17.86-13.229 7.344 1.523 12.664 8.077 12 16.189zm113.07 13.521h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15
                15z"/></g></svg>`,
        iconExtend: `<svg id="Layer_2"  x="{xCar}" y="30" height="48" viewBox="0 0 128 128" width="48" xmlns="http://www.w3.org/2000/svg" data-name="Layer 2"><path d="m82.474 7.367a26.847 26.847 0 0 0
                -36.948 0 80.317 80.317 0 0 0 -24.943 58.033v55.85a6.758 6.758 0 0 0 6.75 6.75h73.334a6.758 6.758 0 0 0 6.75-6.75v-55.85a80.317 80.317 0 0 0 -24.943-58.033zm-58.391 66.55h4.334v31h-4.334zm76.584
                 50.583h-73.334a3.254 3.254 0 0 1 -3.25-3.25v-12.833h6.084a1.75 1.75 0 0 0 1.75-1.75v-34.5a1.75 1.75 0 0 0 -1.75-1.75h-6.084v-5.017a76.8 76.8 0 0 1 23.852-55.494 23.347 23.347 0 0 1 32.13 0
                 76.8 76.8 0 0 1 23.852 55.494v5.02h-6.084a1.75 1.75 0 0 0 -1.75 1.75v34.5a1.75 1.75 0 0 0 1.75 1.75h6.084v12.83a3.254 3.254 0 0 1 -3.25 3.25zm3.25-19.583h-4.334v-31h4.334z"/><path d="m92.036
                 58.479a79.886 79.886 0 0 0 -1.566-8.739 68.343 68.343 0 0 0 -8.555-19.7 64.579 64.579 0 0 0 -9.763-11.79 11.848 11.848 0 0 0 -16.3 0 64.6 64.6 0 0 0 -9.762 11.791 68.381 68.381 0 0 0 -8.556
                 19.7 55.413 55.413 0 0 0 -1.566 8.741 64.229 64.229 0 0 0 -.381 6.916v45.852a1.75 1.75 0 0 0 1.75 1.75h53.33a1.75 1.75 0 0 0 1.75-1.75v-45.85a64.229 64.229 0 0 0 -.381-6.916zm-3.119
                 36.771h-49.834v-5h49.834zm-44.864-54h39.894a61.77 61.77 0 0 1 2.538 7.167h-44.97a61.77 61.77 0 0 1 2.538-7.167zm38.247-3.5h-36.6c.861-1.709 1.8-3.381 2.815-5h30.974c1.011 1.619 1.954
                 3.291 2.811 5zm-42.574 18.928q.348-2.4.886-4.761h46.776q.561 2.48.917 5h-48.605c.006-.08.014-.16.026-.239zm48.966 3.739c.136 1.653.225 3.313.225 4.98v2.186h-49.834v-2.183c0-1.667.089-3.327.225-4.98zm.225
                  15.666h-49.834v-5h49.834zm-49.834 10.667v-7.167h49.834v7.167zm19.173-65.959a8.348 8.348 0 0 1 11.488 0 61 61 0 0 1 7.386 8.46h-26.26a61 61 0 0 1 7.386-8.46zm-19.173
                  88.709v-10.75h49.834v10.75z"/></svg>`,
        key: '8', name: 'boat'
      })
    ],
    icons2: {
      car: new IconType({
        icon: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    transform="rotate({iconRotate})" fill="{iconFill}" viewBox="0 0 48.997 48.998" style="enable-background:new 0 0 48.997 48.998;"
                    xml:space="preserve">
                <g>
                    <path d="M45.961,18.702c-0.033-0.038-0.061-0.075-0.1-0.112l-1.717-1.657c1.424-0.323,2.957-1.516,2.957-2.74
                        c0-1.426-1.979-1.932-3.668-1.932c-1.766,0-1.971,1.21-1.992,2.065l-4.43-4.271c-0.9-0.891-2.607-1.592-3.883-1.592H24.5h-0.002
                        h-8.63c-1.275,0-2.981,0.701-3.882,1.592l-4.429,4.271c-0.023-0.855-0.228-2.065-1.992-2.065c-1.691,0-3.669,0.506-3.669,1.932
                        c0,1.224,1.534,2.417,2.958,2.74l-1.717,1.657c-0.039,0.037-0.066,0.074-0.1,0.112C1.2,20.272,0,23.184,0,25.297v6.279
                        c0,1.524,0.601,2.907,1.572,3.938v2.435c0,1.424,1.192,2.585,2.658,2.585h3.214c1.466,0,2.657-1.159,2.657-2.585v-0.623h14.397
                        H24.5h14.396v0.623c0,1.426,1.19,2.585,2.658,2.585h3.213c1.467,0,2.657-1.161,2.657-2.585v-2.435
                        c0.972-1.031,1.572-2.414,1.572-3.938v-6.279C48.998,23.184,47.798,20.272,45.961,18.702z M13.613,11.953
                        c0.623-0.519,1.712-0.913,2.255-0.913h8.63H24.5h8.63c0.543,0,1.632,0.394,2.255,0.913l5.809,5.63H24.5h-0.002H7.805L13.613,11.953
                        z M1.993,24.347c0-1.546,1.21-2.801,2.704-2.801c1.493,0,6.372,2.864,6.372,4.41s-4.879,1.188-6.372,1.188
                        C3.203,27.144,1.993,25.894,1.993,24.347z M10.102,34.573H9.587H9.072l-3.055,0.005c-0.848-0.264-1.446-0.572-1.869-0.903
                        c-0.214-0.167-0.378-0.341-0.506-0.514c-0.129-0.175-0.223-0.347-0.284-0.519c-0.38-1.074,0.405-2.061,0.405-2.061h5.214
                        l3.476,3.99L10.102,34.573L10.102,34.573z M31.996,34.575H24.5h-0.002h-7.496c-1.563,0-2.832-1.269-2.832-2.831h10.328H24.5h10.328
                        C34.828,33.308,33.559,34.575,31.996,34.575z M32.654,29.812H24.5h-0.002h-8.154c-1.7,0-3.08-2.096-3.08-4.681h11.234H24.5h11.234
                        C35.734,27.717,34.354,29.812,32.654,29.812z M45.641,32.644c-0.062,0.172-0.156,0.344-0.285,0.518
                        c-0.127,0.173-0.291,0.347-0.506,0.514c-0.422,0.331-1.021,0.641-1.869,0.903l-3.055-0.005h-0.515h-0.515h-2.353l3.478-3.99h5.213
                        C45.234,30.583,46.02,31.568,45.641,32.644z M44.301,27.144c-1.492,0-6.371,0.356-6.371-1.188s4.879-4.41,6.371-4.41
                        c1.494,0,2.704,1.255,2.704,2.801C47.005,25.892,45.795,27.144,44.301,27.144z"/>
                </g>
                </svg>`, iconExtend: "", key: "1", name: "car"
      }),
      direction: new IconType({
        icon: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 488.98 488.98" transform="rotate({iconRotate})" fill="{iconFill}" style="enable-background:new 0 0 488.98 488.98;" xml:space="preserve">
                <g>
                    <g>
                        <path d="M409.49,485.75l-165-80.9l-165,80.9c-25.3,12.4-52.4-13.1-41.6-39.1l178.5-427.9c10.4-25,45.8-25,56.3,0l178.4,427.9
                            C461.89,472.55,434.79,498.15,409.49,485.75z"/>
                    </g>
                </g>
                </svg>
                `, iconExtend: "", key: "2", name: "direction"
      }),
      container: new IconType({
        icon: `<svg version="1.1" id="Capa_1" transform="rotate({rotate})"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="36px" height="36px"
                viewBox="0 0 36 36" enable-background="new 0 0 36 36" xml:space="preserve">
           <g>
               <path fill="#1C1C1C" d="M1.263,22.207V6.891c0-0.851,0.69-1.541,1.541-1.541h15.309c0.852,0,1.541,0.69,1.541,1.541v15.316
                   c0,0.283-0.23,0.514-0.514,0.514H1.777C1.493,22.721,1.263,22.49,1.263,22.207z"/>
               <path fill="#1C1C1C" d="M7.676,24.258H0.514C0.23,24.258,0,24.488,0,24.771v1.559c0,0.283,0.23,0.514,0.514,0.514h5.741
                   C6.405,25.815,6.924,24.906,7.676,24.258z"/>
               <path fill="#1C1C1C" d="M35.486,24.258h-1.25v-6.98c0-0.82-0.245-1.621-0.703-2.3l-3.127-4.63c-0.764-1.132-2.041-1.81-3.406-1.81
                   h-4.85c-0.567,0-1.027,0.46-1.027,1.027v14.693h-7.941c0.752,0.648,1.271,1.558,1.421,2.586h8.375
                   c0.297-2.04,2.053-3.613,4.174-3.613s3.877,1.573,4.175,3.613h4.159c0.283,0,0.514-0.23,0.514-0.514v-1.559
                   C36,24.488,35.77,24.258,35.486,24.258z M30.369,15.905h-6.4c-0.283,0-0.514-0.229-0.514-0.514v-3.552
                   c0-0.284,0.23-0.514,0.514-0.514h3.9c0.166,0,0.323,0.081,0.42,0.218l2.5,3.553C31.028,15.437,30.785,15.905,30.369,15.905z"/>
           </g>
           <g>
               <path fill="#1C1C1C" d="M10.429,24.258c-1.765,0-3.196,1.432-3.196,3.196c0,1.766,1.431,3.196,3.196,3.196s3.196-1.431,3.196-3.196
                   C13.625,25.689,12.194,24.258,10.429,24.258z M10.429,29.053c-0.883,0-1.598-0.716-1.598-1.599c0-0.882,0.715-1.599,1.598-1.599
                   s1.598,0.717,1.598,1.599C12.027,28.337,11.312,29.053,10.429,29.053z"/>
               <path fill="#1C1C1C" d="M27.152,24.258c-1.765,0-3.195,1.432-3.195,3.196c0,1.766,1.431,3.196,3.195,3.196
                   c1.766,0,3.197-1.431,3.197-3.196C30.35,25.689,28.918,24.258,27.152,24.258z M27.152,29.053c-0.882,0-1.598-0.716-1.598-1.599
                   c0-0.882,0.715-1.599,1.598-1.599s1.599,0.717,1.599,1.599C28.751,28.337,28.035,29.053,27.152,29.053z"/>
           </g>
           </svg>
                `, iconExtend: "", key: "3", name: "container"
      }),
      truck: new IconType({
        icon: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    transform="rotate({iconRotate})" fill="{iconFill}" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;" xml:space="preserve">
                <g>
                    <path d="M21.474,377.522V117.138c0-14.469,11.729-26.199,26.199-26.199h260.25c14.469,0,26.198,11.73,26.198,26.199v260.385
                        c0,4.823-3.909,8.733-8.733,8.733H30.207C25.383,386.256,21.474,382.346,21.474,377.522z M231.634,466.724
                        c0,30.01-24.329,54.338-54.338,54.338c-30.009,0-54.338-24.328-54.338-54.338c0-30.011,24.329-54.338,54.338-54.338
                        C207.305,412.386,231.634,436.713,231.634,466.724z M204.464,466.724c0-15.005-12.164-27.169-27.169-27.169
                        s-27.17,12.164-27.17,27.169s12.165,27.17,27.17,27.17S204.464,481.729,204.464,466.724z M130.495,412.385H8.733
                        c-4.823,0-8.733,3.91-8.733,8.733v26.495c0,4.823,3.91,8.733,8.733,8.733h97.598C108.879,438.862,117.704,423.418,130.495,412.385z
                        M515.938,466.724c0,30.01-24.329,54.338-54.338,54.338c-30.01,0-54.338-24.328-54.338-54.338
                        c0-30.011,24.328-54.338,54.338-54.338C491.609,412.385,515.938,436.713,515.938,466.724z M488.77,466.724
                        c0-15.005-12.165-27.169-27.17-27.169c-15.006,0-27.169,12.164-27.169,27.169s12.164,27.17,27.169,27.17
                        S488.77,481.729,488.77,466.724z M612,421.118v26.495c0,4.823-3.91,8.733-8.733,8.733h-70.704
                        c-5.057-34.683-34.906-61.427-70.961-61.427c-36.062,0-65.912,26.745-70.969,61.427H248.261
                        c-2.549-17.483-11.373-32.928-24.164-43.961h134.994V162.594c0-9.646,7.82-17.466,17.466-17.466h82.445
                        c23.214,0,44.911,11.531,57.9,30.77l53.15,78.721c7.796,11.547,11.962,25.161,11.962,39.094v118.672h21.253
                        C608.09,412.385,612,416.295,612,421.118z M523.408,256.635l-42.501-60.393c-1.636-2.324-4.3-3.707-7.142-3.707H407.47
                        c-4.822,0-8.733,3.91-8.733,8.733v60.393c0,4.824,3.91,8.733,8.733,8.733h108.798C523.342,270.394,527.48,262.421,523.408,256.635z
                        "/>
                </g>
                </svg>`, iconExtend: "", key: "3", name: "truck"
      }),
      other: new IconType({
        icon: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        transform="rotate({iconRotate})" fill="{iconFill}" viewBox="0 0 188.111 188.111" style="enable-background:new 0 0 188.111 188.111;" xml:space="preserve">
                <g>
                    <path d="M94.052,0C42.19,0.001,0,42.194,0.001,94.055c0,51.862,42.191,94.056,94.051,94.057
                        c51.864-0.001,94.059-42.194,94.059-94.056C188.11,42.193,145.916,0,94.052,0z M94.052,173.111
                        c-43.589-0.001-79.051-35.465-79.051-79.057C15,50.464,50.462,15.001,94.052,15c43.593,0,79.059,35.464,79.059,79.056
                        C173.11,137.646,137.645,173.11,94.052,173.111z"/>
                    <path d="M94.053,50.851c-23.821,0.002-43.202,19.384-43.202,43.204c0,23.824,19.381,43.206,43.203,43.206
                        c23.823,0,43.205-19.382,43.205-43.205C137.259,70.232,117.877,50.851,94.053,50.851z M94.054,122.261
                        c-15.551,0-28.203-12.653-28.203-28.206c0-15.55,12.652-28.203,28.203-28.204c15.553,0,28.205,12.653,28.205,28.205
                        C122.259,109.608,109.606,122.261,94.054,122.261z"/>
                    <circle cx="94.055" cy="94.056" r="16.229"/>
                </g>
                </svg>`, iconExtend: "", key: "4", name: "other"
      }),
      motorbike: new IconType({
        icon: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        transform="rotate({iconRotate})" fill="{iconFill}" viewBox="0 0 581.4 581.4" style="enable-background:new 0 0 581.4 581.4;" xml:space="preserve"
                    >
                <g>
                    <g id="Layer_1_117_">
                        <g>
                            <path d="M289.425,376.125c-28.05,0-51,22.95-51,51V530.4c0,28.05,22.95,51,51,51s51-22.95,51-51V427.125
                                C340.425,399.075,317.476,376.125,289.425,376.125z"/>
                            <path d="M474.3,63.75h-98.175C364.65,26.775,330.226,0,289.425,0s-75.225,26.775-86.7,63.75h-96.9
                                c-14.025,0-25.5,11.475-25.5,25.5s11.475,25.5,25.5,25.5H204l0,0c6.375,21.675,20.4,39.525,39.525,51
                                c-68.85,20.4-119.85,84.15-119.85,159.375V459c0,22.95,19.125,42.075,42.075,42.075h34.425V428.4
                                c0-49.726,40.8-90.525,90.525-90.525c49.725,0,90.525,40.8,90.525,90.525v72.675h34.425c22.95,0,42.075-19.125,42.075-42.075
                                V326.4c0-75.226-51-140.25-119.851-159.375c19.125-11.475,33.15-29.325,39.525-51l0,0h98.175c14.025,0,25.5-11.475,25.5-25.5
                                C499.8,75.225,488.325,63.75,474.3,63.75z M289.425,141.525c-28.05,0-51-22.95-51-51c0-28.05,22.95-51,51-51s51,22.95,51,51
                                C341.7,118.575,318.75,141.525,289.425,141.525z"/>
                        </g>
                    </g>
                </g>
                </svg>`, iconExtend: "", key: "5", name: "motorbike"
      }),
      motorcycles: new IconType({
        icon: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        transform="rotate({iconRotate})" fill="{iconFill}" viewBox="0 0 467.168 467.168" style="enable-background:new 0 0 467.168 467.168;"
                        xml:space="preserve">
                <g>
                    <g>
                        <path d="M76.849,210.531C34.406,210.531,0,244.937,0,287.388c0,42.438,34.406,76.847,76.849,76.847
                            c30.989,0,57.635-18.387,69.789-44.819l18.258,14.078c0,0,134.168,0.958,141.538-3.206c0,0-16.65-45.469,4.484-64.688
                            c2.225-2.024,5.021-4.332,8.096-6.777c-3.543,8.829-5.534,18.45-5.534,28.558c0,42.446,34.403,76.846,76.846,76.846
                            c42.443,0,76.843-34.415,76.843-76.846c0-42.451-34.408-76.849-76.843-76.849c-0.697,0-1.362,0.088-2.056,0.102
                            c5.551-3.603,9.093-5.865,9.093-5.865l-5.763-5.127c0,0,16.651-3.837,12.816-12.167c-3.848-8.33-44.19-58.28-44.19-58.28
                            s7.146-15.373-7.634-26.261l-7.098,15.371c0,0-18.093-12.489-25.295-10.084c-7.205,2.398-18.005,3.603-21.379,8.884l-3.358,3.124
                            c0,0-0.95,5.528,4.561,13.693c0,0,55.482,17.05,58.119,29.537c0,0,3.848,7.933-12.728,9.844l-3.354,4.328l-8.896,0.479
                            l-16.082-36.748c0,0-15.381,4.082-23.299,10.323l1.201,6.24c0,0-64.599-43.943-125.362,21.137c0,0-44.909,12.966-76.37-26.897
                            c0,0-0.479-12.968-76.367-10.565l5.286,5.524c0,0-5.286,0.479-7.444,3.841c-2.158,3.358,1.2,6.961,18.494,6.961
                            c0,0,39.153,44.668,69.17,42.032l42.743,20.656l18.975,32.42c0,0,0.034,2.785,0.23,7.045c-4.404,0.938-9.341,1.979-14.579,3.09
                            C139.605,232.602,110.832,210.531,76.849,210.531z M390.325,234.081c29.395,0,53.299,23.912,53.299,53.299
                            c0,29.39-23.912,53.294-53.299,53.294c-29.394,0-53.294-23.912-53.294-53.294C337.031,257.993,360.932,234.081,390.325,234.081z
                                M76.849,340.683c-29.387,0-53.299-23.913-53.299-53.295c0-29.395,23.912-53.299,53.299-53.299
                            c22.592,0,41.896,14.154,49.636,34.039c-28.26,6.011-56.31,11.99-56.31,11.99l3.619,19.933l55.339-2.444
                            C124.365,322.116,102.745,340.683,76.849,340.683z M169.152,295.835c1.571,5.334,3.619,9.574,6.312,11.394l-24.696,0.966
                            c1.058-3.783,1.857-7.666,2.338-11.662L169.152,295.835z"/>
                    </g>
                </g>
                </svg>`, iconExtend: "", key: "6", name: "motorcycles"
      }),
      horse: new IconType({
        icon: `<svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
                            <path d="M 39.3125 0 C 39.230469 0 39.140625 0.0117188 39.0625 0.03125 C 38.9375 0.0585938 36.582031 0.6875 35.3125 1.9375 C 34.042969 3.1875
                            33.371094 4.839844 33.125 6.09375 C 20.824219 6.492188 9 13.394531 9 28.09375 C 9 31.894531 10.113281 38 11.8125 40.5 C 11.8125 40.5 13.410156
                            43.757813 21.15625 48.09375 C 26.425781 49.851563 31.726563 50 33.46875 50 C 33.78125 50 33.96875 50.003906 34.03125 50 C 34.453125 49.984375
                            34.808594 49.714844 34.9375 49.3125 C 34.976563 49.191406 35.90625 46.308594 35.90625 43.1875 C 35.90625 39.941406 34.605469 37.867188 33.34375
                            35.84375 C 31.996094 33.6875 30.59375 31.453125 30.59375 27.5 C 30.59375 25.476563 31.324219 23.96875 32.75 23 C 34.8125 26.863281 38.71875
                            27.804688 40.34375 28.03125 C 40.675781 29.003906 41.425781 30.648438 42.78125 31.1875 C 43.226563 31.363281 43.878906 31.480469 44.65625
                            31.1875 C 44.960938 31.359375 45.320313 31.476563 45.6875 31.4375 C 46.75 31.421875 47.816406 30.675781 48.5625 29.40625 C 48.632813 29.289063
                            48.71875 29.167969 48.8125 29.03125 C 49.628906 27.800781 51.300781 25.261719 47.59375 21.6875 C 45.820313 14.066406 42.644531 10.304688 41.4375
                            8.875 L 41.28125 8.65625 C 40.601563 7.84375 39.882813 7.085938 39.46875 6.65625 L 40.28125 1.15625 C 40.332031 0.828125 40.210938 0.503906
                            39.96875 0.28125 C 39.785156 0.113281 39.554688 0.00390625 39.3125 0 Z M 32.40625 0.1875 C 32.367188 0.1875 32.320313 0.210938 32.28125 0.21875
                            C 31.515625 0.3125 30.972656 0.953125 30.4375 1.5625 C 30.207031 1.824219 29.785156 2.285156 29.5625 2.40625 C 29.351563 2.351563 28.925781
                            2.027344 28.65625 1.84375 C 27.882813 1.3125 26.910156 0.652344 25.8125 0.90625 C 24.898438 1.089844 24.34375 1.976563 23.8125 2.8125 C 23.601563
                            3.148438 23.21875 3.742188 23 3.90625 C 22.773438 3.839844 22.316406 3.546875 22.03125 3.375 C 21.175781 2.863281 20.273438 2.355469 19.375 2.65625
                            C 18.429688 2.96875 18.125 4.011719 17.84375 4.9375 C 17.75 5.246094 17.59375 5.75 17.46875 5.96875 C 17.214844 5.933594 16.71875 5.699219
                            16.40625 5.5625 C 15.503906 5.171875 14.492188 4.753906 13.5625 5.21875 C 12.589844 5.707031 12.625 6.84375 12.65625 7.78125 C 12.667969 8.058594
                            12.660156 8.5 12.625 8.71875 C 12.375 8.832031 11.960938 8.910156 11.5625 8.96875 C 10.753906 9.089844 9.855469 9.222656 9.15625 9.84375 C
                            8.457031 10.445313 8.4375 11.375 8.4375 12.1875 C 8.4375 12.511719 8.4375 13.085938 8.34375 13.21875 C 8.210938 13.328125 7.652344 13.425781
                            7.3125 13.46875 C 6.472656 13.574219 5.4375 13.699219 4.90625 14.5 C 4.273438 15.339844 4.652344 16.402344 4.96875 17.25 C 5.09375 17.582031
                            5.332031 18.199219 5.3125 18.34375 C 5.191406 18.496094 4.582031 18.714844 4.21875 18.84375 C 3.28125 19.179688 2.128906 19.578125 1.75
                            20.71875 C 1.527344 21.5 2.003906 22.273438 2.46875 23 C 2.59375 23.199219 2.78125 23.492188 2.875 23.6875 C 2.722656 23.816406 2.390625
                            23.980469 2.1875 24.09375 C 1.515625 24.464844 0.601563 24.984375 0.40625 26.0625 C 0.296875 26.839844 0.867188 27.507813 1.40625 28.15625
                            C 1.578125 28.363281 1.847656 28.703125 1.96875 28.90625 C 1.84375 29.03125 1.59375 29.199219 1.4375 29.3125 C 0.832031 29.761719 0 30.378906
                            0 31.40625 C 0 32.105469 0.507813 32.71875 1.03125 33.375 C 1.222656 33.613281 1.519531 34.011719 1.59375 34.1875 C 1.609375 34.367188
                            1.464844 34.542969 1.15625 34.90625 C 0.753906 35.378906 0.222656 36.023438 0.3125 37 C 0.425781 38.007813 1.394531 38.535156 2.1875
                            38.9375 C 2.425781 39.058594 2.828125 39.253906 2.96875 39.375 C 3.027344 39.519531 3.132813 39.644531 3.25 39.75 C 3.34375 39.835938
                            5.398438 41.617188 10.125 41.59375 C 7.996094 38.417969 7 31.664063 7 28.09375 C 7 12.636719 19.015625 5.027344 31.78125 4.15625 C 32.113281
                            3.335938 32.667969 2.066406 33.78125 0.75 C 32.945313 0.214844 32.566406 0.1875 32.40625 0.1875 Z M 42.1875 1.90625 L 41.59375 6 C 41.761719
                            6.183594 41.945313 6.390625 42.125 6.59375 C 42.4375 5.621094 42.914063 4.558594 43.71875 3.6875 C 43.960938 3.425781 44.070313 3.058594
                            43.96875 2.71875 C 43.867188 2.378906 43.566406 2.113281 43.21875 2.03125 C 42.898438 1.953125 42.578125 1.90625 42.25 1.90625 C 42.230469
                            1.90625 42.207031 1.90625 42.1875 1.90625 Z M 39.5 15 C 40.328125 15 41 15.671875 41 16.5 C 41 17.328125 40.328125 18 39.5 18 C 38.671875
                            18 38 17.328125 38 16.5 C 38 15.671875 38.671875 15 39.5 15 Z"/></svg>`,
        iconExtend: "", key: "7", name: "horse"
      })

    },
    features: new MapFeature(),
    options: new MapOption(),
    mapType: {
      // hybrid:new MapType({
      //     type:"hybrid",
      //     layer: L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      //         maxZoom: 18,
      //         attribution: 'Mapdata&copy;Google',
      //         id: '2',
      //         subdomains:['mt0','mt1','mt2','mt3']
      //     }),
      //     translate:"MAP.HYBRID",
      //     icon:'icon-road'
      // }),
      roadmap: new MapType({
        type: "roadmap",
        layer:
          {
            // link: 'https://{s}.google.com/vt/lyrs=m,traffic&x={x}&y={y}&z={z}',
            //  link: 'http://images.vietbando.com/ImageLoader/GetImage.ashx?Ver=2016&LayerIds=VBD&Level={z}&X={x}&Y={y}',
            link: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
            option: {
              maxZoom: 22,
              attribution: 'Mapdata&copy;Google',
              id: '2',
              subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
            }
          },
        translate: "MAP.FEATURES.ROAD_MAP",
        icon: 'icon-road'
      }),
      satellite: new MapType({
        type: "satellite",
        layer:
          {
            // link: 'https://{s}.google.com/vt/lyrs=s,traffic&x={x}&y={y}&z={z}',
            link: 'https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
            option: {
              maxZoom: 21,
              attribution: 'Tiles &copy;Mapdata&copy;Google',
              id: '3',
              subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
            }
            // link: 'https://{s}.google.com/kh?v=855&hl=en&x={x}&y={y}&z={z}',
            // option: {
            //     maxZoom: 22,
            //     attribution: 'Mapdata&copy;Google',
            //     id: '3',
            //     subdomains: ['khms0', 'khms1']
            // }
          },
        translate: "MAP.FEATURES.SATELLITE",
        icon: 'icon-globe-americas'
      }),

      googleRoadmap: new MapType({
        type: "googleRoadmap",
        layer:
          {
            link: 'https://{s}.google.com/vt/lyrs=m,traffic&x={x}&y={y}&z={z}',
            option: {
              maxZoom: 22,
              attribution: 'Mapdata&copy;Google',
              id: '3',
              subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
            }
          },
        translate: "MAP.FEATURES.GOOGLE_ROAD_MAP",
        icon: 'icon-road'
      }),
      googleSatellite: new MapType({
        type: "satellite",
        layer:
          {
            link: 'https://{s}.google.com/kh?v=855&lyrs=en,traffic&x={x}&y={y}&z={z}',
            option: {
              maxZoom: 22,
              attribution: 'Mapdata&copy;Google',
              id: '3',
              subdomains: ['khms0', 'khms1']
            }
          },
        translate: "MAP.FEATURES.GOOGLE_SATELLITE",
        icon: 'icon-globe-americas'
      }),
      // roadmapTopgps: new MapType({
      //     type: "roadmapTopgps",
      //     layer:
      //     {
      //         // link: 'https://{s}.google.com/vt/lyrs=m,traffic&x={x}&y={y}&z={z}',
      //         link: 'https://map.vnetgps.com/osm_tiles/{z}/{x}/{y}.png',
      //         option: {
      //             maxZoom: 20,
      //             attribution: 'MapData©Topgps',
      //             id: '4',
      //             subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      //         }
      //     },
      //     translate: "MAP.FEATURES.ROAD_MAP_TOPGPS",
      //     icon: 'icon-road',
      // }),
    },
    infoBox: {
      collapse: false,
      base: {
        label: {
          value: "name",
          list: [
            {value: "name", translate: "MAP.INFO_BOX.NAME"},
            {value: "imei", translate: "MAP.INFO_BOX.IMEI"},
            {value: "numberPlate", translate: "MAP.INFO_BOX.NUMBER_PLATE"},
            // { value: "vin", translate: "MAP.INFO_BOX.VIN" },
          ]
        },
        icon: true,
        title: "",
        speed: true,
        duration: false,
        direction: true,
      },
      advance: {
        icon: true,
        duration: true,
        speed: true,
        battery: true,
        gsm: true,
        numberPlate: true,
        imei: true,
        simno: true,
        vin: true,
        powerVoltage: true,
        distanceToday: true,
        driver: true,
        drivingToday: true,
        updateTime: true,
        group: true,
        geofence: true,
        address: true,
      }
    },
    currentPosition: {latitude: 21.019604, longitude: 105.808548},
    version: "1.0.5"

  }

  configs() {
    return Object.assign({}, this.defaults);
  }
}
