import * as L from 'leaflet';
import {MapType} from './map-type';

export class MapOption {
  mapKey?: string;
  elementProcessText?: string;
  center?: L.LatLng;
  isCluster?: false;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  stykeMarker?: string;
  zoomToPoint?: number;
  panC?: boolean;
  zoomC?: boolean;
  mapTypeC?: boolean;
  scaleC?: boolean;
  overviewMapC?: boolean;
  NavigationControl?: boolean;
  mapType?: MapType;

  constructor(object?: MapOption) {
    this.mapKey = "map";
    this.elementProcessText = this.mapKey + "ProcessText";
    this.center = new L.LatLng(21.00123, 105.8220357);
    this.isCluster = false;
    this.zoom = 12;
    this.maxZoom = 22;
    this.minZoom = 3;
    this.zoomToPoint = 14;
    this.panC = true;
    this.zoomC = true;
    this.mapTypeC = false;
    this.scaleC = true;
    this.overviewMapC = true;
    this.NavigationControl = true;
    this.panC = false;
    this.panC = false;
    this.mapType = new MapType({
      type: "roadmap",
      layer: {
        // link: 'https://{s}.google.com/vt/lyrs=m,traffic&x={x}&y={y}&z={z}',
        link: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        option: {
          maxZoom: 22,
          attribution: 'Mapdata&copy;Google',
          id: '2',
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }
      },
      icon: "icon-road",
      translate: "MAP.ROAD_MAP"
    });

    if (object)
      return Object.assign(this, object);
    return this;
  }
}
